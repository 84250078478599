import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "./LearningPathPage.css";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const formatLabel = (label) =>
  label
    .split("-")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ");

const LearningPathPage = ({ pathId, problems }) => {
  const navigate = useNavigate();
  // const [problems, setProblems] = useState([]);
  // const [loading, setLoading] = useState(true);

  const groupByDifficulty = (problems) => {
    return {
      EASY: problems.filter((p) => p.difficulty === "EASY"),
      MEDIUM: problems.filter((p) => p.difficulty === "MEDIUM"),
      HARD: problems.filter((p) => p.difficulty === "HARD"),
    };
  };

  const difficulties = groupByDifficulty(problems);

  return (
    <div className="path-container">
      <h1 className="path-title">{formatLabel(pathId)} Path</h1>
      {problems.length === 0 ? (
        <p>No problems found.</p>
      ) : (
        <div className="path-list">
          {["EASY", "MEDIUM", "HARD"].map((level) =>
            difficulties[level].length > 0 ? (
              <div key={level} className="difficulty-column">
                <h2 className={`difficulty-heading ${level.toLowerCase()}`}>
                  {level[0] + level.slice(1).toLowerCase()} Level
                </h2>
                <ul className="problem-list">
                  {difficulties[level].map((problem, i) => {
                    const { BEGINNER, PRO } = problem.progress || {};
                    const completeClass =
                      BEGINNER && PRO
                        ? "both-complete"
                        : BEGINNER
                          ? "block-complete"
                          : PRO
                            ? "code-complete"
                            : "";

                    return (
                      <li
                        key={problem.id}
                        className={`problem-list-item ${level.toLowerCase()} ${completeClass}`}
                        onClick={() => navigate(`/problems/${problem.id}`)}
                      >
                        <div className="problem-title-row">
                          <span className="problem-index">{i + 1}.</span>
                          <span className="problem-title">{problem.title}</span>
                        </div>
                        <div className="completion-labels">
                          <button
                            className={`mode-button ${BEGINNER ? "complete" : "incomplete"}`}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent triggering the parent onClick
                              navigate(`/problems/${problem.id}`, {
                                state: {
                                  level: "BEGINNER",
                                  mode: "blocks",
                                  progress: problem.progress,
                                },
                              });
                            }}
                          >
                            Blocks {BEGINNER ? "✅" : "⬜"}
                          </button>
                          <button
                            className={`mode-button ${PRO ? "complete" : "incomplete"}`}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent triggering the parent onClick
                              navigate(`/problems/${problem.id}`, {
                                state: {
                                  level: "PRO",
                                  mode: "code",
                                  progress: problem.progress,
                                },
                              });
                            }}
                          >
                            Code {PRO ? "✅" : "⬜"}
                          </button>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null
          )}
        </div>
      )}
    </div>
  );
};

export default LearningPathPage;
