import React, { useState, useEffect } from "react";
import "./LevelSwitcher.css";

const LevelSwitcher = ({
  level,
  handleLevelChange,
  renderSolutionTabs,
  progress,
  demoMode,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const beginnerComplete = progress?.BEGINNER === true;
  const proComplete = progress?.PRO === true;
  const intermediateComplete =
    progress?.INTERMEDIATE?.solutionIds?.length === 4;

  const normalizedLevel = level?.toLowerCase();

  return (
    <div className="level-switcher-wrapper">
      <button
        className={`slider-button ${
          normalizedLevel === "beginner" ? "active" : ""
        } ${beginnerComplete ? "level-complete" : ""}`}
        onClick={() => handleLevelChange("Beginner")}
      >
        Beginner
      </button>

      {!demoMode && (
        <div className="intermediate-dropdown-wrapper">
          <div
            className="intermediate-progress-wrapper"
            style={{
              "--fill-percentage": `${
                (progress?.INTERMEDIATE?.solutionIds?.length || 0) * 25
              }%`,
            }}
          >
            <button
              className={`slider-button 
              ${normalizedLevel === "intermediate" ? "active" : ""} 
              ${intermediateComplete ? "intermediate-complete" : ""}`}
              onClick={() => {
                handleLevelChange("Intermediate");
                setShowDropdown((prev) => !prev);
              }}
              onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
            >
              Intermediate ▾
            </button>
          </div>

          {normalizedLevel === "intermediate" && showDropdown && (
            <div className="solution-dropdown">{renderSolutionTabs()}</div>
          )}
        </div>
      )}
      
      <button
        className={`slider-button ${
          normalizedLevel === "pro" ? "active" : ""
        } ${proComplete ? "level-complete" : ""}`}
        onClick={() => handleLevelChange("Pro")}
      >
        Pro
      </button>
    </div>
  );
};

export default LevelSwitcher;
