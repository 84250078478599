import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./AccountPage.css";
import NavBarV2 from "../components/NavBarV2";

const AccountPage = () => {
  const [account, setAccount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userProgress, setUserProgress] = useState([]);
  const [achievements, setAchievements] = useState({
    attemptedBeginner: 0,
    completedBeginner: 0,
    attemptedExpert: 0,
  });
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordChangeMessage, setPasswordChangeMessage] = useState("");
  const { id } = useParams();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccountAndProgress = async () => {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");
      if (!token) {
        setIsLoading(false);
        navigate("/");
        return;
      }
      try {
        const response = await axios.get(`${baseUrl}/users/account/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response) {
          throw new Error("Failed to fetch account details");
        }
        const accountInfo = response.data;

        try {
          const progressResponse = await axios.get(
            `${baseUrl}/progress/${userId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (!progressResponse) {
            throw new Error("Failed to fetch user progress");
          }
          const progressData = progressResponse.data;
          setUserProgress(progressData);
        } catch (progressError) {
          console.error(progressError);
        }
        setAccount(accountInfo);
        // setUserProgress(accountInfo.progress);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
        console.error(error);
      }
    };
    fetchAccountAndProgress();
  }, []);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${baseUrl}/users/account/change-password`,
        { currentPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPasswordChangeMessage(response.data.message);
      setCurrentPassword("");
      setNewPassword("");
    } catch (error) {
      console.error("Error changing password:", error);
      setPasswordChangeMessage("Failed to change password.");
    }
  };

  if (!account) {
    return (
      <div className="no-account-container">
        <p>You are not logged in. Please log in or create an account.</p>
      </div>
    );
  }
  return (
    <div className="account-page-main">
      <div className="account-page-container">
        <h2 className="account-header">Account Details</h2>
        <div className="account-details-top">
          <div className="account-section-containers profile-section">
            <h3 className="account-section-headers">Profile</h3>
            <p className="profile-text">Username: {account.username}</p>
            <p className="profile-text">Email: {account.email}</p>
            <p className="profile-text">Membership: {account.role}</p>
          </div>
          <div className="account-section-containers achievements-section">
            <h3 className="account-section-headers">Achievements</h3>
            <div className="achievements-progress">
              <div className="achievement-progress-bar">
                <p className="achievement-label">
                  Attempted 5 Beginner Problems
                </p>
                <progress value={achievements.attemptedBeginner} max="5" />
                <p className="achievement-progress-text">
                  {achievements.attemptedBeginner}/5
                </p>
              </div>
              <div className="achievement-progress-bar">
                <p className="achievement-label">
                  Completed 5 Beginner Problems
                </p>
                <progress value={achievements.completedBeginner} max="5" />
                <p className="achievement-progress-text">
                  {achievements.completedBeginner}/5
                </p>
              </div>
              <div className="achievement-progress-bar">
                <p className="achievement-label">Attempted 5 Expert Problems</p>
                <progress value={achievements.attemptedExpert} max="5" />
                <p className="achievement-progress-text">
                  {achievements.attemptedExpert}/5
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="account-section-containers progress-section">
          <h3 className="account-section-headers">User Progress</h3>
          {account?.progress?.length > 0 ? (
            <table className="progress-table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Attempts</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {userProgress?.map((trackedProblem) => (
                  <tr key={trackedProblem.id}>
                    <td>{trackedProblem.problem.title}</td>
                    <td>{trackedProblem.level}</td>
                    <td>{trackedProblem.attempts}</td>
                    <td>
                      <Link
                        to={`/problems/${trackedProblem.problem.id}`}
                        className={`progress-status-link ${
                          trackedProblem.completed ? "completed" : "in-progress"
                        }`}
                      >
                        {trackedProblem.completed ? "Completed" : "In Progress"}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="progress-empty">
              You Haven't Started Any Problems Yet
            </p>
          )}
        </div>
        <div className="account-section-containers password-reset-section">
          <h3 className="account-section-headers">Change Password</h3>
          <form onSubmit={handlePasswordChange}>
            <div className="password-reset-form-group">
              <label>
                Current Password:
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
              </label>
            </div>
            <div className="password-reset-form-group">
              <label>
                New Password:
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </label>
            </div>
            <button type="submit" className="password-reset-button">
              Change Password
            </button>
          </form>
          {passwordChangeMessage && (
            <p className="password-change-message">{passwordChangeMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
