import React, { useState } from "react";
import "./SolutionSection.css";

const SolutionSection = ({
  showCompletionModal,
  setShowCompletionModal,
  level,
  handleLevelChange,
  solutionBlocks,
  renderBlock,
  removeFromSolution,
  handleAddBlockInside,
  blocks,
  moveBlockUp,
  moveBlockDown,
  showHintButton,
  fetchHint,
  getBlockStyle,
  solutionCompleted,
  solutionName,
}) => {
  const [hoveredSolutionIndex, setHoveredSolutionIndex] = useState(null);

  return (
    <div className="solution-blocks-holder">
      <div className="solution-header">
        <div className="header-row">
          <span className="solution-title">{solutionName}</span>
          {solutionCompleted && (
            <span className="solved-inline">✅ Solved</span>
          )}
        </div>
      </div>

      {/* {showCompletionModal && (
        <div className="completion-modal">
          <div className="modal-content">
            <h2>🎉 This Problem Has Already Been Completed!</h2>
            <p>What would you like to do next?</p>
            <div className="modal-buttons">
              <button
                onClick={() => setShowCompletionModal(false)}
                className="try-again-button"
              >
                Try Again
              </button>
              <button
                onClick={() => {
                  setShowCompletionModal(false);
                  if (level === "Beginner") handleLevelChange("Intermediate");
                  else if (level === "Intermediate")
                    handleLevelChange("Expert");
                  else if (level === "Expert") handleLevelChange("Pro");
                }}
                className="try-another-level-button"
              >
                Try Another Level
              </button>
            </div>
          </div>
        </div>
      )} */}

      <div className="solution-blocks">
        {solutionBlocks.map((block, index) => {
          const style = getBlockStyle(block.type);
          const isHovered = hoveredSolutionIndex === index;

          return (
            <div
              key={block.id}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                color: style.color,
                paddingRight: "10px",
                marginBottom: "0px",
                borderRadius: "12px",
                maxWidth: "80%",
                fontFamily: "'Fira Code', monospace",
                fontSize: "14px",
                fontWeight: "bold",
                cursor: "pointer",
                // height: "30px"
                // boxShadow:
                //   "0 2px 6px rgba(0,0,0,0.15), inset 0 1px 0 rgba(255,255,255,0.1)",
                transition: "transform 0.2s ease-in-out",
                transform: isHovered ? "translateY(-2px)" : "translateY(0)",
              }}
              onMouseEnter={() => setHoveredSolutionIndex(index)}
              onMouseLeave={() => setHoveredSolutionIndex(null)}
            >
              {/* Render Block Content */}
              <div className="render-block-content" style={{ flex: 1 }}>
                {renderBlock(
                  block,
                  index,
                  removeFromSolution,
                  handleAddBlockInside,
                  blocks
                )}
              </div>

              {/* Arrow Button Container (invisible until hovered) */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "40px", // Reserve consistent width
                  visibility: isHovered ? "visible" : "hidden",
                  opacity: isHovered ? 1 : 0,
                  transition: "opacity 0.2s ease-in-out",
                }}
              >
                {/* UP Arrow */}
                <button
                  style={{
                    cursor: index === 0 ? "not-allowed" : "pointer",
                    padding: "5px",
                    border: "none",
                    borderRadius: "6px",
                    fontSize: "18px",
                    backgroundColor: index === 0 ? "#ccc" : "#2c3e50",
                    color: "#fff",
                    marginBottom: "6px",
                  }}
                  onClick={() => moveBlockUp(index)}
                  disabled={index === 0}
                >
                  ↑
                </button>

                {/* DOWN Arrow */}
                <button
                  style={{
                    cursor:
                      index === solutionBlocks.length - 1
                        ? "not-allowed"
                        : "pointer",
                    padding: "5px",
                    border: "none",
                    borderRadius: "6px",
                    fontSize: "18px",
                    backgroundColor:
                      index === solutionBlocks.length - 1 ? "#ccc" : "#2c3e50",
                    color: "#fff",
                  }}
                  onClick={() => moveBlockDown(index)}
                  disabled={index === solutionBlocks.length - 1}
                >
                  ↓
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {/* {showHintButton && (
        <button className="hint-button" onClick={fetchHint}>
          Hint
        </button>
      )} */}
    </div>
  );
};

export default SolutionSection;
