import React from "react";

const ProblemOfTheDay = () => {
  const containerStyle = {
    textAlign: "center",
    marginTop: "50px",
    fontFamily: "'Poppins', sans-serif",
  };

  const titleStyle = {
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: "#4caf50", // Vibrant green for energy.
    textShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
    marginBottom: "20px",
  };

  const comingSoonContainerStyle = {
    display: "inline-block",
    padding: "15px 30px",
    background: "linear-gradient(135deg, #ffcc00, #ff9900)",
    borderRadius: "12px",
    boxShadow: "0 8px 15px rgba(0, 0, 0, 0.2)",
    animation: "pulse 2s infinite",
  };

  const comingSoonTextStyle = {
    fontSize: "1.8rem",
    fontWeight: "600",
    color: "#fff",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
  };

  const bricksContainerStyle = {
    display: "flex",
    justifyContent: "center",
    gap: "8px",
    marginTop: "20px",
  };

  const brickStyle = (color) => ({
    width: "40px",
    height: "40px",
    backgroundColor: color,
    borderRadius: "6px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
    transform: "rotate(-5deg)",
    animation: "float 3s ease-in-out infinite",
  });

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Problem of the Day</h1>
      <div style={comingSoonContainerStyle}>
        <span style={comingSoonTextStyle}>Coming Soon</span>
      </div>
      <div style={bricksContainerStyle}>
        <div style={brickStyle("#f44336")}></div>
        <div style={brickStyle("#2196f3")}></div>
        <div style={brickStyle("#ffeb3b")}></div>
        <div style={brickStyle("#4caf50")}></div>
      </div>
    </div>
  );
};

export default ProblemOfTheDay;
