import React from "react";
import "./TestCases.css";
import { ClipLoader } from "react-spinners";

const TestCases = ({
  testResults,
  currentTestIndex,
  setCurrentTestIndex,
  allTestsPassed,
  feedbackMessage,
  problem,
  isChecking,
}) => {
  return (
    <>
      <h2 className="test-heading">Test Cases</h2>
      {isChecking ? (
        <div className="spinner-wrapper">
          <ClipLoader color="#00C851" size={60} />
        </div>
      ) : testResults.length > 0 ? (
        <div className="test-case-container">
          {/* Tabs */}
          <div className="test-tabs">
            {testResults.map((test, index) => (
              <button
                key={index}
                onClick={() => setCurrentTestIndex(index)}
                className={`test-tab ${test.passed ? "passed-tab" : "failed-tab"} ${
                  currentTestIndex === index ? "active" : ""
                }`}
              >
                {test.passed ? "✅" : "❌"} Test {index + 1}
              </button>
            ))}
          </div>

          {/* Details */}
          <div className="test-case-card">
            <div className="test-case-content">
              <div className="test-input">
                <strong>Input:</strong>
                <pre>{testResults[currentTestIndex].input}</pre>
              </div>
              <div className="test-output">
                <strong>Expected Output:</strong>
                <pre>{testResults[currentTestIndex].expectedOutput}</pre>
              </div>
              <div className="test-output">
                <strong>Your Output:</strong>
                <pre>{testResults[currentTestIndex].actualOutput}</pre>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Submit some code to see the test cases.</p>
      )}
    </>
  );
};

export default TestCases;
