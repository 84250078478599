import React from "react";
import "./ProblemSection.css";

const ProblemSection = ({ problem, level }) => {
  if (!problem) return null;

  return (
    <div className="new-problem-section">
      <div className="problem-metadata">
        <span className={`difficulty ${problem.difficulty?.toLowerCase()}`}>
          {problem.difficulty}
        </span>
        <h2 className="new-problem-title">{problem.title}</h2>
      </div>
      <p className="new-problem-level">{level} Level</p>
      <p className="new-problem-description">{problem.description}</p>

      {/* Ensure testCases is an array before mapping or accessing indices */}
      {Array.isArray(problem.testCases) && problem.testCases.length > 0 && (
        <div className="new-examples-section">
          {problem.testCases[0] && (
            <div className="new-example-card">
              <p className="new-example-title">Example 1:</p>
              <p className="new-example-content">
                Input: {problem.testCases[0]?.inputs ?? "N/A"}
              </p>
              <p className="new-example-content">
                Output: {problem.testCases[0]?.expectedOutput ?? "N/A"}
              </p>
            </div>
          )}
          <br />
          {problem.testCases[1] && (
            <div className="new-example-card">
              <p className="new-example-title">Example 2:</p>
              <p className="new-example-content">
                Input: {problem.testCases[1]?.inputs ?? "N/A"}
              </p>
              <p className="new-example-content">
                Output: {problem.testCases[1]?.expectedOutput ?? "N/A"}
              </p>
            </div>
          )}
        </div>
      )}

      {Array.isArray(problem.constraints) && problem.constraints.length > 0 && (
        <div className="new-constraints-section">
          <h3>Constraints</h3>
          <ul className="new-constraints-list">
            {problem.constraints.map((constraint, idx) => (
              <li key={idx} className="new-constraint-item">
                {constraint}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProblemSection;
