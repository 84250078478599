import React, { useState, useEffect, useRef, useMemo } from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable"; // Import the swipeable hook
import axios from "axios";
import { motion } from "framer-motion";
import "codemirror/lib/codemirror.css"; // Basic CodeMirror styles
import "codemirror/theme/material.css"; // You can pick other themes if you prefer
import "codemirror/mode/javascript/javascript"; // JavaScript mode (you can import other modes if necessary)
import "codemirror/mode/python/python"; // Python
import "codemirror/mode/clike/clike"; // Java, C++, C#
import "codemirror/mode/swift/swift"; // Swift
import "codemirror/addon/edit/closebrackets"; // Import auto-close brackets addon
import "codemirror/addon/edit/matchbrackets"; // Auto-indent on bracket closure
import confetti from "canvas-confetti";
import "./DragAndDropWorkspace.css";
import Swal from "sweetalert2"; // Import SweetAlert for pop-ups
import BeginnerTutorial from "./BeginnerTutorial";
import Split from "react-split";
import ProblemSection from "./ProblemSection";
import SolutionSection from "./SolutionSection";
import { render } from "react-dom";
import BlocksSection from "./BlocksSection";
import LevelSwitcher from "./LevelSwitcher";
import CodeEditor from "./CodeEditor";
import TestCases from "./TestCases";

const DragAndDropWorkspace = ({
  problem,
  solutions,
  level,
  setSelectedLevel,
  activeSolutionIndex,
  setActiveSolutionIndex,
  user,
  selectedLanguage,
  loading,
  isCompletionStatus,
  mode,
  setSolutionBlocks,
  setBlocks,
  blocks,
  setCodeSnippet,
  codeSnippet,
  solutionBlocks,
  handleLevelChange,
  updateProgress,
  solutionCompleted,
  activeSolutionName,
}) => {
  // const correctSolution = problem?.correctSolution;
  // const initialBlocks = [];

  // const [blocks, setBlocks] = useState(initialBlocks); // Block library
  const [blocksByLevel, setBlocksByLevel] = useState({
    Beginner: [],
    Intermediate: [],
    Expert: [],
    Pro: [],
  }); // Persist available blocks for each level
  // const [solutionBlocks, setSolutionBlocks] = useState([]); // Solution section
  const [solutionBlocksByLevel, setSolutionBlocksByLevel] = useState({
    Beginner: [],
    Intermediate: [],
    Expert: [],
    Pro: [],
  }); // Track solution blocks for each level
  // const [codeSnippet, setCodeSnippet] = useState("");
  const [selectedContainerIndex, setSelectedContainerIndex] = useState(null); // Track selected container block index
  const [isSelectingBlock, setIsSelectingBlock] = useState(false); // Flag to show/hide block selection dropdown
  const [feedbackMessage, setFeedbackMessage] = useState(""); // Feedback message for solution check
  const [problemSolutions, setProblemSolutions] = useState([]); // Holds the solutions from the database
  // const [selectedLevel, setSelectedLevel] = useState(level); // State to manage the current level
  const [activePage, setActivePage] = useState(0); // To track swiping between pages
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedTab, setSelectedTab] = useState("Problem"); // New state for tab control
  const [selectedSolutionIndex, setSelectedSolutionIndex] = useState(0); // Index for solution tab
  const [highlightedBlock, setHighlightedBlock] = useState(null); // To track the highlighted block
  const [showHintButton, setShowHintButton] = useState(false); // Manage hint button visibility
  const [hintUsed, setHintUsed] = useState(false);
  const lastShuffledLevel = useRef(null); // Track the last shuffled level
  const [solutionBlocksBySolution, setSolutionBlocksBySolution] = useState({});
  const [blocksBySolution, setBlocksBySolution] = useState({});
  const [showTutorial, setShowTutorial] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(0);
  const [areBlocksVisible, setAreBlocksVisible] = useState(level !== "Pro"); // Visibility state for blocks
  // const [proCodeSnippet, setProCodeSnippet] = useState("");
  const [selectedText, setSelectedText] = useState(""); // To store highlighted text
  const editorRef = useRef(null); // Ref to access the CodeMirror instance
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 }); // Button position
  const prevLanguage = useRef(selectedLanguage);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [testResults, setTestResults] = useState([]);
  const [allTestsPassed, setAllTestsPassed] = useState(null);
  const [currentTestIndex, setCurrentTestIndex] = useState(0); // Track the selected test case
  const [selectedTimeComplexity, setSelectedTimeComplexity] = useState("");
  const [selectedSpaceComplexity, setSelectedSpaceComplexity] = useState("");
  const [complexityFeedback, setComplexityFeedback] = useState(null);
  const [hoveredBlockIndex, setHoveredBlockIndex] = useState(null);
  const [shakeEditor, setShakeEditor] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [splitSizes, setSplitSizes] = useState([40, 60]);
  const [testCaseSizes, setTestCaseSizes] = useState([60, 40]);
  const [isChecking, setIsChecking] = useState(false);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  //useEffect for persisting Beginner Blocks
  // useEffect(() => {
  //   if (!problem || !solutions) return;

  //   if (level === "Beginner") {
  //     const optimalSolution = problem.solutions.find(
  //       (solution) => solution.isOptimal
  //     );
  //     if (optimalSolution) {
  //       const beginnerBlocks = optimalSolution.blocks.filter(
  //         (block) => block.level === "BEGINNER"
  //       );

  //       // Save Beginner blocks if they are not already saved
  //       if (!blocksByLevel.Beginner?.length) {
  //         setBlocksByLevel((prev) => ({
  //           ...prev,
  //           Beginner: beginnerBlocks,
  //         }));
  //       }
  //     }
  //   }
  // }, [problem, solutions]);

  // useEffect(() => {
  //   if (
  //     ((level === "Beginner" || level === "Pro") && levelCompleted) ||
  //     ((level === "Intermediate" || level === "Expert") &&
  //       levelCompleted &&
  //       solutionCompleted)
  //   ) {
  //     setShowCompletionModal(true);
  //   }
  // }, [level, levelCompleted, solutionCompleted, activeSolutionIndex]);

  // const handleLevelChange = (newLevel) => {
  //   console.log(`🔄 Switching from ${level} to ${newLevel}`);

  //   // ✅ Save the current Pro code snippet before switching
  //   if (level === "Pro") {
  //     const proStorageKey = `pro-code-${problem.id}-${selectedLanguage}`;
  //     console.log("📌 Saving latest Pro code before switching:", codeSnippet);

  //     localStorage.setItem(proStorageKey, codeSnippet);
  //   }

  //   // Set activeSolutionIndex to 0 for Beginner
  //   if (newLevel === "Beginner") {
  //     setActiveSolutionIndex(0);
  //   }

  //   // Change the level
  //   setSelectedLevel(newLevel);

  //   // Storage key for retrieving saved data
  //   const storageKey = `solution-${problem.id}-${newLevel}-${newLevel === "Beginner" ? 0 : activeSolutionIndex}-${selectedLanguage}`;
  //   const savedState = localStorage.getItem(storageKey);
  //   const parsedState = savedState ? JSON.parse(savedState) : null;
  //   // const proStorageKey = `pro-code-${problem.id}-${selectedLanguage}`;
  //   // const savedProCode = localStorage.getItem(proStorageKey) || "";

  //   if (newLevel === "Pro") {
  //     // No blocks should be shown in Pro level
  //     const proStorageKey = `pro-code-${problem.id}-${selectedLanguage}`;
  //     const savedProCode = localStorage.getItem(proStorageKey) || "";
  //     console.log("🚀 Loading Pro code after switch:", savedProCode);

  //     // setCodeSnippet(savedProCode);
  //   } else {
  //     // Restore saved state or reset to empty if not found
  //     setSolutionBlocks(parsedState?.solutionBlocks || []);
  //     setBlocks(parsedState?.blocks || []);
  //     setCodeSnippet(parsedState?.codeSnippet || "");
  //   }
  // };

  useEffect(() => {
    if (loading || !problem || !solutions) return; // Skip if data is still loading or not available

    // if (level === "Pro" && areBlocksVisible) {
    //   return; // Do not overwrite blocks for Pro level if already revealed
    // }

    setTestResults([]); // Reset test cases whenever level or solution changes
    setAllTestsPassed(null); // Reset test status
    setCurrentTestIndex(0); // Reset to the first test case

    // Utility function to shuffle the blocks
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    // Retrieve saved state from local storage
    const getSavedState = (key) => {
      const savedState = localStorage.getItem(key);
      return savedState ? JSON.parse(savedState) : null;
    };

    // Save state to local storage
    const saveState = (key, data) => {
      localStorage.setItem(key, JSON.stringify(data));
    };

    // Unique key for local storage based on level and solution
    const storageKey = `solution-${problem.id}-${level}-${activeSolutionIndex}-${selectedLanguage}`;

    if (level === "Pro") {
      // // Retrieve and set the saved Pro level code snippet
      // const proStorageKey = `pro-code-${problem.id}-${selectedLanguage}`;
      // const savedProCode = localStorage.getItem(proStorageKey) || "";
      // setCodeSnippet(savedProCode);
      // return; // Exit early for Pro level
    }

    const restoreStateForLevel = () => {
      if (level === "Pro") {
        setSolutionBlocks([]); // Pro level doesn't use solution blocks
        setBlocks([]);
        return; // Pro level doesn't display blocks
        // const proStorageKey = `pro-code-${problem.id}-${selectedLanguage}`;
        // const savedProCode = localStorage.getItem(proStorageKey) || "";
        // setCodeSnippet(savedProCode);
        // return;
      }

      // Load saved state if available
      const savedState = getSavedState(storageKey);
      if (savedState) {
        setSolutionBlocks(savedState.solutionBlocks || []);
        setBlocks(savedState.blocks || []);
        setCodeSnippet(savedState.codeSnippet || "");
        return;
      }

      // If no saved state, set up default blocks
      const filteredSolutions = solutions.filter(
        (solution) => solution.language === selectedLanguage.toUpperCase()
      );

      if (!filteredSolutions.length) {
        setBlocks([]);
        setSolutionBlocks([]);
        setCodeSnippet("");
        return;
      }

      if (level === "Beginner") {
        const optimalSolution = filteredSolutions.find(
          (solution) => solution.isOptimal
        );

        if (optimalSolution) {
          const beginnerBlocks = optimalSolution.blocks.filter(
            (block) => block.level === "BEGINNER"
          );

          const shuffledBlocks = shuffleArray([...beginnerBlocks]);
          setBlocks(shuffledBlocks);
          setSolutionBlocks([]);
          setCodeSnippet("");
        }
      } else if (level === "Intermediate" || level === "Expert") {
        const solution = filteredSolutions[activeSolutionIndex];
        if (solution) {
          const blockLevel = solution.isOptimal
            ? level === "Intermediate"
              ? "BEGINNER"
              : "EXPERT"
            : level.toUpperCase();

          const levelBlocks = solution.blocks.filter(
            (block) => block.level === blockLevel
          );

          const shuffledBlocks = shuffleArray([...levelBlocks]);
          setBlocks(shuffledBlocks);
          setSolutionBlocks([]);
          setCodeSnippet("");
        }
      }
    };

    restoreStateForLevel();
  }, [
    level,
    problem,
    solutions,
    activeSolutionIndex,
    selectedLanguage,
    loading,
    areBlocksVisible,
  ]);

  // Save the blocks and solutions whenever they change
  useEffect(() => {
    if (!problem || level === "Pro") return;

    // prevent saving empty state accidentally
    if (
      blocks.length === 0 &&
      solutionBlocks.length === 0 &&
      codeSnippet === ""
    ) {
      return;
    }

    const storageKey = `solution-${problem.id}-${level}-${activeSolutionIndex}-${selectedLanguage}`;

    const stateToSave = {
      solutionBlocks,
      blocks,
      codeSnippet,
    };

    console.log("📝 Saving to localStorage:", stateToSave);

    localStorage.setItem(storageKey, JSON.stringify(stateToSave));
  }, [
    solutionBlocks,
    blocks,
    codeSnippet,
    level,
    problem,
    activeSolutionIndex,
    selectedLanguage,
  ]);

  const countAllBlocks = (blocks) => {
    let count = 0;

    for (const block of blocks) {
      count += 1;
      if (block.children && Array.isArray(block.children)) {
        count += countAllBlocks(block.children);
      }
      if (block.nestedBlocks && Array.isArray(block.nestedBlocks)) {
        count += countAllBlocks(block.nestedBlocks);
      }
    }

    return count;
  };

  useEffect(() => {
    const totalBlocks = countAllBlocks(solutionBlocks);

    if (totalBlocks < 3) {
      setSplitSizes([45, 55]);
    } else if (totalBlocks <= 4) {
      setSplitSizes([50, 50]);
    } else if (totalBlocks <= 5) {
      setSplitSizes([60, 40]);
    } else if (totalBlocks <= 6) {
      setSplitSizes([65, 35]);
    } else {
      setSplitSizes([75, 25]);
    }
  }, [solutionBlocks]);

  // Helper function to collect all blocks (including nested ones) in the solution section
  const collectAllBlocksFromSolution = (blocks) => {
    const allBlocks = [];

    const collectBlocks = (blockList) => {
      blockList.forEach((block) => {
        allBlocks.push(block);
        if (block.nestedBlocks?.length > 0) {
          collectBlocks(block.nestedBlocks);
        }
      });
    };

    collectBlocks(blocks);
    return allBlocks;
  };

  const optimalSolution = solutions.find((solution) => solution.isOptimal);

  const handleSelectionChange = (editor) => {
    const selection = editor.getSelection(); // Get the selected text
    if (selection.trim()) {
      // Get the cursor position in pixels
      const cursorCoords = editor.cursorCoords(true, "window");
      setButtonPosition({
        x: cursorCoords.left,
        y: cursorCoords.bottom + 5, // Slightly below the cursor
      });
    }
    setSelectedText(selection);
  };

  const handleWhatDoesThisMean = async () => {
    if (!selectedText.trim()) {
      Swal.fire({
        title: "No Code Selected",
        text: "Please highlight some code to get an explanation.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    try {
      // Show loading modal
      Swal.fire({
        title: "Processing...",
        text: "Fetching an explanation for the selected code.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Send API request to the backend
      const response = await axios.post(
        `${baseUrl}/problems/explain`, // Update this URL as per your setup
        {
          code: selectedText,
          language: selectedLanguage,
        }
      );

      const explanation = response.data.explanation;

      // Show explanation in a popup
      Swal.fire({
        title: "Code Explanation",
        html: `<pre style="text-align: left; white-space: pre-wrap; font-family: monospace;">${explanation}</pre>`,
        icon: "info",
        confirmButtonText: "Close",
      });
    } catch (error) {
      console.error("Error explaining code:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to fetch explanation. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  // Handle level change to reset blocks visibility
  useEffect(() => {
    if (level === "Pro") {
      setAreBlocksVisible(false); // Hide blocks for Pro level
    } else {
      setAreBlocksVisible(true); // Show blocks for other levels
    }
  }, [level]);

  const handleRevealBlocks = () => {
    Swal.fire({
      title: "Reminder!",
      text: "Blocks on the Pro Level will not generate any code; they are just here to guide you through your own hand-coded solution.",
      icon: "info",
      showDenyButton: true, // Adds a second button
      confirmButtonText: "Reveal Blocks",
      denyButtonText: "Try Without Revealing",
      customClass: {
        popup: "pro-level-popup", // Optional: Add custom styling
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Reveal blocks if the user clicks "Reveal Blocks"
        const optimalSolution = solutions.find(
          (solution) =>
            solution.isOptimal &&
            solution.language === selectedLanguage.toUpperCase()
        );

        if (optimalSolution) {
          // Extract Expert blocks for the optimal solution
          const expertBlocks = optimalSolution.blocks.filter(
            (block) => block.level === "EXPERT"
          );
          console.log(expertBlocks);

          // Set the solution blocks to the Expert blocks
          // setSolutionBlocks(expertBlocks);

          // Set the blocks to render in the blocks library
          setBlocks(expertBlocks);

          // Update the code snippet (if needed for Pro level guidance)
          // setCodeSnippet(generateCodeFromBlocks(expertBlocks));
        }

        setAreBlocksVisible(true); // Make the blocks visible
      } else if (result.isDenied) {
        // Just close the popup without revealing blocks
      }
    });
  };

  // // useEffect for Tutorial Screen
  // useEffect(() => {
  //   if (level === "Beginner" && !user?.isLoggedIn) {
  //     const hasSeenTutorial = localStorage.getItem("beginnerTutorialSeen");
  //     if (!hasSeenTutorial) {
  //       setShowTutorial(true);
  //     }
  //   }
  // }, [level, user]);

  const handleNextStep = () => {
    if (tutorialStep < 2) {
      setTutorialStep(tutorialStep + 1);
    } else {
      handleCloseTutorial();
    }
  };

  const handleCloseTutorial = () => {
    setShowTutorial(false);
    localStorage.setItem("beginnerTutorialSeen", "true"); // Mark tutorial as seen
    setTutorialStep(0); // Reset tutorial step
  };

  const blockHighlightClass =
    showTutorial && tutorialStep === 0 ? "highlight" : "";
  const solutionHighlightClass =
    showTutorial && tutorialStep === 1 ? "highlight" : "";
  const codeSnippetHighlightClass =
    showTutorial && tutorialStep === 2 ? "highlight" : "";

  const fetchHint = async () => {
    if (!problem || !codeSnippet) return;

    try {
      const response = await axios.post(`${baseUrl}/problems/generateHint`, {
        problemName: problem.title,
        codeInSnippet: codeSnippet,
      });

      if (response.data?.hint) {
        // Parse the hint to format it as a bulleted list
        const rawHint = response.data.hint;

        // Assume that the hint contains lines prefixed with "-" or "*"
        const formattedHint = rawHint
          .split("\n")
          .map(
            (line) =>
              line.trim().startsWith("-") || line.trim().startsWith("*")
                ? `<li>${line.replace(/^[-*]\s*/, "")}</li>` // Convert to list item
                : line // Keep non-bulleted lines as is
          )
          .join("");

        // Show the hint in a pop-up
        Swal.fire({
          title: "Hint",
          html: `
            <div style="text-align: left; line-height: 1.5;">
              <ul style="padding-left: 20px; list-style-type: disc; color: #333;">
                ${formattedHint}
              </ul>
            </div>
          `,
          icon: "info",
          confirmButtonText: "Got it!",
          customClass: {
            popup: "hint-popup",
          },
        });
      } else {
        Swal.fire({
          title: "No Hint Available",
          text: "Unable to generate a hint at this time. Please try again later.",
          icon: "warning",
          confirmButtonText: "Okay",
        });
      }
    } catch (error) {
      console.error("Error fetching hint:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while fetching the hint. Please try again.",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  // // Function to handle the Hint button click
  // const handleHint = () => {
  //   if (level === "Beginner") {
  //     const nextBlock = findNextBlockToHighlight(optimalSolution);
  //     if (nextBlock) {
  //       setHighlightedBlock(nextBlock.content); // Highlight the next block
  //       setTimeout(() => {
  //         setHighlightedBlock(null); // Remove highlight after 2 seconds
  //       }, 2000);
  //     }
  //   } else if (level === "Intermediate") {
  //     const activeSolution = solutions[activeSolutionIndex]; // Get the currently active solution
  //     const nextBlock = findNextBlockToHighlight(activeSolution);
  //     if (nextBlock) {
  //       setHighlightedBlock(nextBlock.content); // Highlight the next block
  //       setTimeout(() => {
  //         setHighlightedBlock(null); // Remove highlight after 2 seconds
  //       }, 2000);
  //     }
  //   }
  // };

  // // Function to find the next block to highlight
  // const findNextBlockToHighlight = (solution) => {
  //   if (!solution || !solution.blocks) {
  //     return null;
  //   }

  //   // Find the first block in the selected solution that hasn't been added yet
  //   for (let block of solution.blocks) {
  //     const isAlreadyInSolution = solutionBlocks.some(
  //       (solBlock) => solBlock.content === block.content
  //     );
  //     if (!isAlreadyInSolution) {
  //       return block; // Return the next block to be added
  //     }
  //   }
  //   return null; // No more blocks to suggest
  // };

  // const renderSolutionTabs = () => {
  //   // Render tabs for Intermediate and Expert levels
  //   if (
  //     (level === "Intermediate" || level === "Expert") &&
  //     solutions.length > 1
  //   ) {
  //     return (
  //       <div className="solution-tabs">
  //         {solutions.map((solution, index) => (
  //           <button
  //             key={index}
  //             className={`solution-tab ${
  //               index === activeSolutionIndex ? "active" : ""
  //             }`}
  //             onClick={() => setActiveSolutionIndex(index)}
  //           >
  //             {solution?.name}
  //             {/* {solution.isOptimal ? "Optimal" : "Non-optimal"} */}
  //           </button>
  //         ))}
  //       </div>
  //     );
  //   }
  //   return null; // Return null if the conditions are not met
  // };

  // Add event listener to handle window resize for dynamic responsiveness
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Swipe handlers for mobile
  const handlers = useSwipeable({
    onSwipedLeft: () => setActivePage(1), // Swipe left to show solution
    onSwipedRight: () => setActivePage(0), // Swipe right to show problem
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Allows swipe on desktop with mouse drag
  });

  // Handle tab change
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  // CodeMirror configuration
  const options = {
    mode:
      selectedLanguage === "javascript"
        ? "javascript"
        : selectedLanguage === "python"
          ? "python"
          : selectedLanguage === "java"
            ? "text/x-java"
            : selectedLanguage === "csharp"
              ? "text/x-csharp" // Correct for C#
              : selectedLanguage === "cpp"
                ? "text/x-c++src" // Correct for C++
                : selectedLanguage === "swift"
                  ? "text/x-swift" // Swift mode
                  : "javascript", // Fallback to JavaScript
    theme: "material",
    lineNumbers: true,
    readOnly: level !== "Pro", // Editable only on Pro level
    ...(level === "Pro" && {
      autoCloseBrackets: true, // Enables automatic closing of brackets
      matchBrackets: true, // Auto-indents when pressing Enter after a bracket
    }),
  };

  const addToSolution = (blockContent) => {
    console.log(level);
    const block = blocks?.find((b) => b.content === blockContent);
    if (block) {
      // Check if the block already exists in the solution
      const blockExists = solutionBlocks.some((b) => b.id === block.id);
      if (!blockExists) {
        const newBlock = block.isContainer
          ? { ...block, nestedBlocks: block.nestedBlocks || [] }
          : block;

        // Add the block to the solution section
        setSolutionBlocks((prev) => {
          const updatedSolutionBlocks = [...prev, newBlock];

          // Update the specific solution's blocks while preserving nested blocks
          if (level === "Intermediate" || level === "Expert") {
            const solutionKey = `${level}-${activeSolutionIndex}`;
            setSolutionBlocksBySolution((prevSolution) => {
              const existingSolutionBlocks = prevSolution[solutionKey] || [];
              const mergedBlocks = mergeNestedBlocks(
                existingSolutionBlocks,
                updatedSolutionBlocks
              );
              console.log(mergedBlocks);
              return {
                ...prevSolution,
                [solutionKey]: mergedBlocks,
              };
            });
          }

          // Update the code snippet only if the level is not "Pro"
          if (level !== "Pro") {
            setCodeSnippet(generateCodeFromBlocks(updatedSolutionBlocks));
          }

          return updatedSolutionBlocks;
        });

        // Collect all blocks from the added block, including nested blocks
        const allBlocksToRemove = collectAllBlocksFromSolution([block]);

        // Remove the block and all its nested blocks from the blocks section
        setBlocks((prevBlocks) =>
          prevBlocks.filter(
            (b) => !allBlocksToRemove.some((nested) => nested.id === b.id)
          )
        );

        // Update the block library for Intermediate/Expert levels
        if (level === "Intermediate" || level === "Expert") {
          const solutionKey = `${level}-${activeSolutionIndex}`;
          setBlocksBySolution((prev) => ({
            ...prev,
            [solutionKey]: prev[solutionKey]?.filter(
              (b) => !allBlocksToRemove.some((nested) => nested.id === b.id)
            ),
          }));
        }
        console.log("blocksBySolution", blocksBySolution);
      }
    }
  };

  //arrow functions for blocks on solution board
  const moveBlockUp = (index) => {
    if (index <= 0 || solutionBlocks.length === 0) return; // No unnecessary updates

    setSolutionBlocks((prev) => {
      const newOrder = [...prev];
      [newOrder[index], newOrder[index - 1]] = [
        newOrder[index - 1],
        newOrder[index],
      ];

      // Check if the new order is the same as the old one
      if (JSON.stringify(prev) === JSON.stringify(newOrder)) return prev;

      setCodeSnippet(generateCodeFromBlocks(newOrder)); // Update code snippet only when necessary
      return newOrder; // Return updated order
    });
  };

  const moveBlockDown = (index) => {
    if (index >= solutionBlocks.length - 1 || solutionBlocks.length === 0)
      return; // No unnecessary updates

    setSolutionBlocks((prev) => {
      const newOrder = [...prev];
      [newOrder[index], newOrder[index + 1]] = [
        newOrder[index + 1],
        newOrder[index],
      ];

      // Check if the new order is the same as the old one
      if (JSON.stringify(prev) === JSON.stringify(newOrder)) return prev;

      setCodeSnippet(generateCodeFromBlocks(newOrder)); // Update code snippet only when necessary
      return newOrder; // Return updated order
    });
  };

  const moveNestedUp = (containerIndexPath, blockIndex) => {
    if (blockIndex <= 0) return; // Prevent moving the first block up

    setSolutionBlocks((prev) => {
      const recursiveMove = (blocks, path) => {
        const [currentIndex, ...remainingPath] = path;

        return blocks.map((block, index) => {
          if (index === currentIndex) {
            if (remainingPath.length === 0) {
              // Perform the move within the nestedBlocks array
              const newNestedBlocks = [...block.nestedBlocks];
              [newNestedBlocks[blockIndex - 1], newNestedBlocks[blockIndex]] = [
                newNestedBlocks[blockIndex],
                newNestedBlocks[blockIndex - 1],
              ];
              return {
                ...block,
                nestedBlocks: newNestedBlocks,
              };
            } else {
              // Recurse deeper
              return {
                ...block,
                nestedBlocks: recursiveMove(
                  block.nestedBlocks || [],
                  remainingPath
                ),
              };
            }
          }
          return block;
        });
      };

      return recursiveMove(prev, containerIndexPath);
    });
  };

  const moveNestedDown = (containerIndexPath, blockIndex) => {
    setSolutionBlocks((prev) => {
      const recursiveMove = (blocks, path) => {
        const [currentIndex, ...remainingPath] = path;

        return blocks.map((block, index) => {
          if (index === currentIndex) {
            if (remainingPath.length === 0) {
              // Perform the move within the nestedBlocks array
              const newNestedBlocks = [...block.nestedBlocks];
              [newNestedBlocks[blockIndex], newNestedBlocks[blockIndex + 1]] = [
                newNestedBlocks[blockIndex + 1],
                newNestedBlocks[blockIndex],
              ];
              return {
                ...block,
                nestedBlocks: newNestedBlocks,
              };
            } else {
              // Recurse deeper
              return {
                ...block,
                nestedBlocks: recursiveMove(
                  block.nestedBlocks || [],
                  remainingPath
                ),
              };
            }
          }
          return block;
        });
      };

      return recursiveMove(prev, containerIndexPath);
    });
  };

  const removeFromSolution = (index) => {
    const blockToRemove = solutionBlocks[index];

    // Helper function to recursively collect all nested blocks
    const collectAllNestedBlocks = (block) => {
      const allBlocks = [];
      const collect = (currentBlock) => {
        if (currentBlock.nestedBlocks?.length) {
          currentBlock.nestedBlocks.forEach(collect); // Collect nested blocks
        }
        allBlocks.push({ ...currentBlock, nestedBlocks: [] }); // Reset nested blocks to an empty array
      };
      collect(block);
      return allBlocks;
    };

    // Collect all blocks from the removed block, including itself
    const allBlocksToReturn = blockToRemove.isContainer
      ? collectAllNestedBlocks(blockToRemove)
      : [blockToRemove];

    // Remove the block from the solution section
    const updatedSolutionBlocks = solutionBlocks.filter(
      (_, idx) => idx !== index
    );
    setSolutionBlocks(updatedSolutionBlocks);

    // Add all extracted blocks back to the blocks section
    setBlocks((prevBlocks) => {
      const updatedBlocks = [
        ...prevBlocks,
        ...allBlocksToReturn.filter(
          (block) => !prevBlocks.some((existing) => existing.id === block.id) // Avoid duplicates
        ),
      ];

      // Deduplicate the blocks by ID
      return updatedBlocks.filter(
        (block, idx, self) => idx === self.findIndex((b) => b.id === block.id)
      );
    });

    // Update blocksBySolution for Intermediate/Expert levels
    if (level === "Intermediate" || level === "Expert") {
      const solutionKey = `${level}-${activeSolutionIndex}`;

      setBlocksBySolution((prev) => {
        const currentBlocks = prev[solutionKey] || [];
        const updatedBlocks = [
          ...currentBlocks,
          ...allBlocksToReturn.filter(
            (block) =>
              !currentBlocks.some((existing) => existing.id === block.id)
          ),
        ];

        return {
          ...prev,
          [solutionKey]: updatedBlocks.filter(
            (block, idx, self) =>
              idx === self.findIndex((b) => b.id === block.id)
          ),
        };
      });

      setSolutionBlocksBySolution((prev) => ({
        ...prev,
        [solutionKey]: prev[solutionKey]?.filter(
          (solutionBlock) => solutionBlock.id !== blockToRemove.id
        ),
      }));
    }

    // Update the code snippet
    if (level !== "Pro") {
      setCodeSnippet(generateCodeFromBlocks(updatedSolutionBlocks));
    }
  };

  const mergeNestedBlocks = (existingBlocks, newBlocks) => {
    console.log("mergeNestedBlocks run");
    const mergeBlock = (existingBlock, newBlock) => {
      if (existingBlock.id === newBlock.id && existingBlock.isContainer) {
        const mergedNestedBlocks = [
          ...(existingBlock.nestedBlocks || []),
          ...(newBlock.nestedBlocks || []),
        ];
        // Deduplicate nested blocks by ID
        const deduplicatedNestedBlocks = mergedNestedBlocks.filter(
          (block, index, self) =>
            index === self.findIndex((b) => b.id === block.id)
        );
        return {
          ...existingBlock,
          nestedBlocks: deduplicatedNestedBlocks,
        };
      }
      return newBlock;
    };

    return newBlocks.map((newBlock) => {
      const existingBlock = existingBlocks.find((b) => b.id === newBlock.id);
      return existingBlock ? mergeBlock(existingBlock, newBlock) : newBlock;
    });
  };

  // Function to recursively find and add a block inside a nested container
  const addBlockToNestedContainer = (
    blocks,
    containerIndexPath,
    blockToAdd
  ) => {
    console.log("addBlockToNestedContainer run");
    if (!Array.isArray(blocks)) return blocks;

    const recursiveAddBlock = (blocks, path) => {
      const [currentIndex, ...remainingPath] = path;

      return blocks.map((block, index) => {
        if (index === currentIndex) {
          if (remainingPath.length === 0) {
            // Add block to the nestedBlocks array
            return {
              ...block,
              nestedBlocks: [...(block.nestedBlocks || []), blockToAdd],
            };
          } else {
            // Recurse deeper into the nested structure
            return {
              ...block,
              nestedBlocks: recursiveAddBlock(
                block.nestedBlocks || [],
                remainingPath
              ),
            };
          }
        }
        return block;
      });
    };

    return recursiveAddBlock(blocks, containerIndexPath);
  };

  // Store the path to the container block (could be multiple levels deep)
  const handleAddBlockInside = (containerIndexPath) => {
    console.log("handleAddBlockInside run");
    setSelectedContainerIndex(containerIndexPath); // Path to the nested container
    setIsSelectingBlock(true); // Open block selection modal
  };

  // Function to add a block inside the container
  const addBlockToContainer = (blockId) => {
    console.log("AddBlockToContainer run");
    const blockToAdd = blocks?.find((b) => b.id === blockId);
    if (!blockToAdd || selectedContainerIndex === null) return;

    setSolutionBlocks((prev) => {
      const updatedSolutionBlocks = addBlockToNestedContainer(
        prev,
        selectedContainerIndex,
        blockToAdd
      );

      // Only update the code snippet if the level is not "Pro"
      if (level !== "Pro") {
        const updatedCode = generateCodeFromBlocks(updatedSolutionBlocks);
        setCodeSnippet(updatedCode);
      }

      // Update solutionBlocksBySolution for Intermediate/Expert levels
      if (level === "Intermediate" || level === "Expert") {
        const solutionKey = `${level}-${activeSolutionIndex}`;
        setSolutionBlocksBySolution((prevSolution) => ({
          ...prevSolution,
          [solutionKey]: updatedSolutionBlocks,
        }));
      }

      return updatedSolutionBlocks;
    });

    // Remove the block from the block library
    setBlocks((prevBlocks) => prevBlocks.filter((b) => b.id !== blockId));

    // Update blocksBySolution for Intermediate/Expert levels
    if (level === "Intermediate" || level === "Expert") {
      const solutionKey = `${level}-${activeSolutionIndex}`;
      setBlocksBySolution((prev) => ({
        ...prev,
        [solutionKey]: prev[solutionKey]?.filter((b) => b.id !== blockId),
      }));
    }

    // Hide the block selection modal
    setIsSelectingBlock(false);
  };

  // Add this function to define styles for different types of blocks
  const getBlockStyle = (type) => {
    const blockStyles = {
      variable: {
        backgroundColor: "#3498db", // Blue for variable blocks
        color: "white",
        borderRadius: 10,
        height: "100%",
        width: "100",
        arrowButtonStyle: { backgroundColor: "#3498db", color: "white" }, // For arrow buttons
      },
      loop: {
        background:
          "linear-gradient(145deg,rgb(255, 204, 39),rgb(255, 243, 24))", // Yellow for loop blocks
        color: "white",
        borderRadius: 10,
        height: "100%",
        width: "65%",
        // border: "2px solid black",
        arrowButtonStyle: { backgroundColor: "#ffeb3b", color: "black" }, // For arrow buttons
      },
      pattern: {
        backgroundColor: "#8bc34a", // Green for pattern blocks
        color: "white",
        height: "100%",
        width: "100",
        borderRadius: 10,
        arrowButtonStyle: { backgroundColor: "#8bc34a", color: "white" }, // For arrow buttons
      },
      action: {
        backgroundColor: "red", // Red for action blocks
        color: "white",
        height: "100%",
        width: "100",
        borderRadius: 10,
        arrowButtonStyle: { backgroundColor: "red", color: "white" }, // For arrow buttons
      },
      return: {
        backgroundColor: "black", // Black for return blocks
        color: "white",
        borderRadius: 10,
        arrowButtonStyle: { backgroundColor: "black", color: "white" }, // For arrow buttons
      },
      function: {
        backgroundColor: "purple", // Black for return blocks
        color: "white",
        borderRadius: 10,
        arrowButtonStyle: { backgroundColor: "purple", color: "white" }, // For arrow buttons
      },
      condition: {
        backgroundColor: "orange", // Orange for if blocks
        color: "white",
        borderRadius: 10,
        arrowButtonStyle: { backgroundColor: "orange", color: "white" }, // For arrow buttons
      },
      insert: {
        backgroundColor: "gray", // Orange for if blocks
        color: "white",
        borderRadius: 10,
        arrowButtonStyle: { backgroundColor: "#f0f0f0", color: "black" }, // For arrow buttons
      },
      default: {
        backgroundColor: "#f0f0f0",
        color: "black",
        borderRadius: 10,
        arrowButtonStyle: { backgroundColor: "#f0f0f0", color: "black" }, // For arrow buttons
      },
    };

    return blockStyles[type] || blockStyles.default;
  };

  // Remove a block from a nested container
  // Remove a block from a nested container
  const removeNestedBlock = (blocks, containerIndexPath, blockIndex) => {
    if (!Array.isArray(blocks)) return blocks;

    const [currentIndex, ...remainingPath] = containerIndexPath;

    return blocks.map((block, index) => {
      if (index === currentIndex) {
        if (remainingPath.length === 0) {
          const removedBlock = block.nestedBlocks[blockIndex];

          // Update solutionBlocksBySolution for Intermediate/Expert
          if (level === "Intermediate" || level === "Expert") {
            const solutionKey = `${level}-${activeSolutionIndex}`;
            setSolutionBlocksBySolution((prev) => {
              const updatedSolutionBlocks = prev[solutionKey]?.map(
                (solutionBlock) =>
                  solutionBlock.id === block.id
                    ? {
                        ...solutionBlock,
                        nestedBlocks: solutionBlock.nestedBlocks.filter(
                          (_, idx) => idx !== blockIndex
                        ),
                      }
                    : solutionBlock
              );

              return {
                ...prev,
                [solutionKey]: updatedSolutionBlocks || [],
              };
            });
          }

          // Add the removed block back to blocksBySolution
          if (level === "Intermediate" || level === "Expert") {
            const solutionKey = `${level}-${activeSolutionIndex}`;
            setBlocksBySolution((prev) => {
              const currentBlocks = prev[solutionKey] || [];
              const updatedBlocks = [
                ...currentBlocks,
                ...(currentBlocks.some((b) => b.id === removedBlock.id)
                  ? []
                  : [removedBlock]),
              ];

              // Deduplicate blocks
              return {
                ...prev,
                [solutionKey]: updatedBlocks.filter(
                  (block, idx, self) =>
                    idx === self.findIndex((b) => b.id === block.id)
                ),
              };
            });
          }

          // Update the code snippet
          setCodeSnippet((prevCode) =>
            generateCodeFromBlocks(
              solutionBlocksBySolution[`${level}-${activeSolutionIndex}`] || []
            )
          );

          // At the target container, remove the nested block
          return {
            ...block,
            nestedBlocks: block.nestedBlocks.filter(
              (_, idx) => idx !== blockIndex
            ),
          };
        } else {
          // Navigate further into nested blocks
          if (level === "Intermediate" || level === "Expert") {
            const solutionKey = `${level}-${activeSolutionIndex}`;
            setSolutionBlocksBySolution((prev) => {
              const updatedSolutionBlocks = prev[solutionKey]?.map(
                (solutionBlock) =>
                  solutionBlock.id === block.id
                    ? {
                        ...solutionBlock,
                        nestedBlocks: removeNestedBlock(
                          solutionBlock.nestedBlocks || [],
                          remainingPath,
                          blockIndex
                        ),
                      }
                    : solutionBlock
              );

              return {
                ...prev,
                [solutionKey]: updatedSolutionBlocks || [],
              };
            });
          }

          return {
            ...block,
            nestedBlocks: removeNestedBlock(
              block.nestedBlocks || [],
              remainingPath,
              blockIndex
            ),
          };
        }
      }
      return block;
    });
  };

  const renderBlock = (
    block,
    index,
    removeFromSolution,
    addBlockToContainer,
    blocks,
    containerIndexPath = []
  ) => {
    const currentPath = [...containerIndexPath, index];
    const blockStyle = getBlockStyle(block.type);

    const addButtonStyle =
      block.type === "variable" || block.type === "return"
        ? {
            marginLeft: "10px",
            alignSelf: "flex-end",
            fontSize: "12px",
            padding: "5px 10px",
            height: "fit-content",
          }
        : {
            marginTop: "10px",
            alignSelf: "center",
            fontSize: "14px",
            padding: "8px 12px",
          };

    const showAddBlockButton =
      block.type === "variable" || block.type === "return"
        ? block.nestedBlocks?.length === 0
        : true;

    if (block.isContainer) {
      return (
        <div
          key={index}
          style={{
            ...blockStyle,
            display: "flex",
            // flexDirection: "column",
            padding: "10px",
            marginBottom: "0px",
            marginLeft: "0px",
            height: "fit-content",
            fontFamily: "'JetBrains Mono', monospace",
            fontSize: "13px",
            fontWeight: 600,
            borderRadius: "14px",
            border: "none",
            cursor: "pointer",
            transition: "all 0.25s ease-in-out",
            boxShadow:
              "inset 0 -4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.15)",
            minWidth: "120px",
            maxWidth: "300px",
            textAlign: "center",
            whiteSpace: "pre-wrap",
            // THIS IS WHERE YOU STYLE FOR CONTAINER BLOCKS IN SOLUTION SECTION
          }}
          onClick={(e) => {
            e.stopPropagation();

            if (containerIndexPath.length === 0) {
              removeFromSolution(index);
            } else {
              setSolutionBlocks((prevSolutionBlocks) => {
                const updatedBlocks = removeNestedBlock(
                  prevSolutionBlocks,
                  containerIndexPath,
                  index
                );

                setCodeSnippet(generateCodeFromBlocks(updatedBlocks));
                return updatedBlocks;
              });

              setBlocks((prevBlocks) => [...prevBlocks, block]);
            }
          }}
        >
          {block.content}
          <div
            style={{
              padding: "10px",
              marginTop: "0px",
              marginBottom: "0px",
              borderRadius: "8px",
              fontFamily: "'Fira Code', monospace",
              fontSize: "12px",
            }}
          >
            {/* Map through Nested Blocks */}
            {block.nestedBlocks?.map((nestedBlock, nestedIndex) => (
              <div
                key={nestedIndex}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "100%",
                  padding: "5px",
                  borderRadius: "10px",
                  transition: "all 0.2s ease-in-out",
                  // boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  // backgroundColor: "rgba(255, 255, 255, 0.05)",
                }}
                onMouseEnter={(e) => setHoveredBlockIndex(nestedIndex)}
                onMouseLeave={() => setHoveredBlockIndex(null)}
              >
                {/* Render Nested Block */}
                {renderBlock(
                  nestedBlock,
                  nestedIndex,
                  removeFromSolution,
                  addBlockToContainer,
                  blocks,
                  currentPath
                )}

                {/* Add Up and Down Arrows for Nested Blocks */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    visibility:
                      hoveredBlockIndex === nestedIndex ? "visible" : "hidden",
                    opacity: hoveredBlockIndex === nestedIndex ? 1 : 0,
                    transition: "opacity 0.2s ease-in-out",
                    width: "40px", // 👈 Reserve consistent space
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      cursor: nestedIndex === 0 ? "not-allowed" : "pointer",
                      padding: "5px",
                      border: "none",
                      borderRadius: "3px",
                      fontSize: "20px",
                      backgroundColor:
                        nestedIndex === 0 ? "lightgray" : "black",
                      color: "white",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log(
                        "Arrow Up clicked for nested block:",
                        currentPath,
                        "nestedIndex:",
                        nestedIndex
                      );
                      moveNestedUp(currentPath, nestedIndex);
                    }}
                    disabled={nestedIndex === 0}
                  >
                    ↑
                  </button>
                  <button
                    style={{
                      cursor:
                        nestedIndex === block.nestedBlocks?.length - 1
                          ? "not-allowed"
                          : "pointer",
                      padding: "5px",
                      border: "none",
                      borderRadius: "3px",
                      fontSize: "20px",
                      backgroundColor:
                        nestedIndex === block.nestedBlocks?.length - 1
                          ? "lightgray"
                          : "black",
                      color: "white",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (nestedIndex >= block.nestedBlocks?.length - 1) {
                        console.log(
                          "Down arrow disabled for last nested block"
                        );
                        return; // Prevent any action for the last block
                      }
                      console.log(
                        "Arrow Down clicked for nested block:",
                        currentPath,
                        "nestedIndex:",
                        nestedIndex
                      );
                      moveNestedDown(currentPath, nestedIndex);
                    }}
                    disabled={nestedIndex >= block.nestedBlocks?.length - 1}
                  >
                    ↓
                  </button>
                </div>
              </div>
            ))}

            {/* Add Block Inside Button */}
            {showAddBlockButton && (
              <button
                style={{
                  ...addButtonStyle,
                  backgroundColor: "lightgray",
                  color: "black",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddBlockInside(currentPath);
                }}
              >
                ➕ Add Block Inside
              </button>
            )}
          </div>
        </div>
      );
    }

    return (
      <div
        key={index}
        className="non-container-block"
        style={{ ...blockStyle, padding: "10px", marginBottom: "5px" }}
        onClick={(e) => {
          e.stopPropagation();

          if (containerIndexPath.length === 0) {
            removeFromSolution(index);
          } else {
            setSolutionBlocks((prevSolutionBlocks) => {
              const updatedBlocks = removeNestedBlock(
                prevSolutionBlocks,
                containerIndexPath,
                index
              );

              setCodeSnippet(generateCodeFromBlocks(updatedBlocks));
              return updatedBlocks;
            });

            setBlocks((prevBlocks) => [...prevBlocks, block]);
          }
        }}
      >
        {block.content}
      </div>
    );
  };

  const functionStructure = useMemo(() => {
    if (!solutions[activeSolutionIndex]?.solutionCode) {
      console.error("solutionCode is undefined or null");
      return { opening: "", closing: "" }; // Provide default values to prevent errors
    }

    const lines = solutions[activeSolutionIndex]?.solutionCode.split("\n");
    const openingLine = lines[0] || ""; // Safeguard in case lines[0] is undefined

    // Detect if the code is Python by checking the presence of `def` in the first line
    const isPython = openingLine.trim().startsWith("def");

    return {
      opening: openingLine,
      closing: isPython ? "" : lines[lines.length - 1] || "", // For Python, no closing line
    };
  }, [solutions, activeSolutionIndex]);

  const extractFunctionStructure = (solutionCode) => {
    // if (level === "Pro") {
    //   return { opening: "", closing: "" }; // No function structure for Pro level
    // }

    if (!solutionCode) {
      return { opening: "", closing: "" };
    }

    const lines = solutionCode.split("\n");
    const openingLine = lines[0] || "";
    const closingLine = lines[lines.length - 1]?.trim();
    const isPython = openingLine.trim().startsWith("def");

    return {
      opening: openingLine,
      closing: isPython || closingLine !== "}" ? "" : closingLine,
    };
  };

  const generateDynamicCode = (solutionCode, blocks) => {
    const { opening, closing } = extractFunctionStructure(solutionCode);
    const generatedCode = generateCodeFromBlocks(blocks, 1); // Generate block code

    // Adjust formatting based on whether there's a closing line
    return closing
      ? `${opening}\n  ${generatedCode}\n${closing}`
      : `${opening}\n  ${generatedCode}`;
  };

  // Recursive function to generate code from blocks, with correct indentation
  const generateCodeFromBlocks = (blocks, indentationLevel = 0) => {
    let code = "";

    blocks?.forEach((block) => {
      const indent = "  ".repeat(indentationLevel); // Two spaces for each indentation level
      if (block.isContainer) {
        // Handle container blocks (e.g., loops or conditionals)
        const nestedCode = generateCodeFromBlocks(
          block.nestedBlocks,
          indentationLevel + 1
        );
        code += `${indent}${block.code
          .replace("// Insert blocks here", nestedCode)
          .trim()}\n`;
      } else {
        // Handle non-container blocks (e.g., variables, actions)
        code += `${indent}${block.code.trim()}\n`;
      }
    });

    return code.trim(); // Trim the final code to remove any excess whitespace
  };

  const resetBlocks = (matchedSolution) => {
    // Get the block content from the matched solution to filter them out
    const usedBlocks = extractUsedBlocksFromSolution(
      matchedSolution.solutionCode
    );

    // Filter out the used blocks from the available blocks
    const remainingBlocks = blocks.filter(
      (block) => !usedBlocks.includes(block.content)
    );

    // Reset the solution blocks (clear the workspace)
    setSolutionBlocks([]);

    // Update the available blocks to remove the used ones
    setBlocks(remainingBlocks);

    // Reset the code snippet area
    setCodeSnippet("");
  };

  // This is a helper function that extracts the blocks that were used in the solution
  const extractUsedBlocksFromSolution = (solutionCode) => {
    // Normalize the solution code to extract the relevant block contents
    const usedBlockContents = [];

    // Example logic: Check each block's code against the solution code
    blocks.forEach((block) => {
      if (solutionCode.includes(block.code)) {
        usedBlockContents.push(block.content); // Collect the block's content
      }
    });

    return usedBlockContents;
  };

  const navigate = useNavigate(); // Initialize navigate

  // Function to navigate to the next problem
  const navigateToNextProblem = async (level) => {
    try {
      const response = await axios.get(`${baseUrl}/problems?level=${level}`);
      const nextProblem = response.data.find((p) => p.id > problem.id); // Find the next problem

      if (nextProblem) {
        navigate(`/problems/${nextProblem.id}`, {
          state: { level },
        });
      } else {
        alert("No more problems available");
      }
    } catch (error) {
      console.error("Error fetching the next problem:", error);
    }
  };

  const launchConfetti = () => {
    const canvas = document.getElementById("confetti-canvas");
    const myConfetti = confetti.create(canvas, {
      resize: true,
      useWorker: true,
    });

    const duration = 1 * 1000;
    const animationEnd = Date.now() + duration;
    const colors = ["#ff0", "#ff4500", "#00c3ff", "#ff1493"];

    const frame = () => {
      myConfetti({
        particleCount: 5,
        angle: 60,
        spread: 75,
        origin: { x: 0 },
        colors: colors,
      });

      myConfetti({
        particleCount: 5,
        angle: 120,
        spread: 75,
        origin: { x: 1 },
        colors: colors,
      });

      if (Date.now() < animationEnd) {
        requestAnimationFrame(frame);
      }
    };

    frame();
  };

  const checkSolution = async () => {
    setIsChecking(true); // Start loading

    // 🧠 Limit for FREE users (run once per day)
    if (user?.role === "FREE_USER") {
      const today = new Date().toISOString().split("T")[0]; // 'YYYY-MM-DD'
      const lastRunDate = localStorage.getItem("lastRunCodeDate");

      if (lastRunDate === today) {
        Swal.fire({
          title: "Daily Limit Reached",
          text: "Free users can only run code once per day. Upgrade for unlimited runs!",
          icon: "warning",
          confirmButtonText: "Upgrade",
          showCancelButton: true,
          cancelButtonText: "Maybe later",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/membership"); // or your upgrade page
          }
        });

        return; // ⛔️ Stop execution
      } else {
        localStorage.setItem("lastRunCodeDate", today); // ✅ Save today's usage
      }
    }

    const normalize = (code) => code.replace(/\s+/g, " ").trim();

    const { opening, closing } = extractFunctionStructure(
      solutions[activeSolutionIndex]?.solutionCode
    );

    const fullUserSolution = codeSnippet.includes(opening)
      ? codeSnippet.trim()
      : `${opening}\n${codeSnippet}\n${closing}`.trim();

    const normalizedUserSolution = normalize(fullUserSolution);
    console.log("✅ Normalized Code:", normalizedUserSolution);

    const userId = localStorage.getItem("userId");
    const problemId = problem.id;
    const solutionId = solutions[activeSolutionIndex]?.id;
    const solutionLevel = level;

    const requestData = {
      language: selectedLanguage,
      code: normalizedUserSolution,
      problemTitle: problem.title,
      level: solutionLevel,
      functionName: problem.functionName,
      inputVars: problem.inputVars, // ✅ Pass inputVars dynamically
      returnType: problem.returnType, // ✅ Pass returnType dynamically
      testCases: problem.testCases, // ✅ Use testCases directly from the database
      solutionCode: solutions[activeSolutionIndex]?.solutionCode,
    };

    console.log("📌 Sending API Request with Data:", requestData);

    try {
      const response = await axios.post(`${baseUrl}/run`, requestData);
      const { output } = response.data;
      console.log("📌 Backend Response:", output);

      const testCaseResults = parseTestResults(output, problem.testCases);
      const allPassed = testCaseResults.every((test) => test.passed);

      setTestResults(testCaseResults);
      setAllTestsPassed(allPassed);

      if (allPassed) {
        // launchConfetti(); // 🎉 Trigger confetti effect
        handleCorrectSolution();

        if (userId) {
          try {
            await axios.put(
              `${baseUrl}/progress/update/${userId}/${problemId}`,
              {
                completed: true,
                solutionId: solutionId || null,
                level: solutionLevel.toUpperCase(),
              }
            );
          } catch (error) {
            console.error("❌ Error updating UserProgress:", error);
          }
        }
      } else {
        handleIncorrectSolution();
      }
    } catch (error) {
      console.error("❌ Error checking solution via backend:", error);
    } finally {
      setIsChecking(false);
    }
  };

  const parseTestResults = (output, testCases) => {
    if (!output || typeof output !== "string") return [];

    console.log("📌 Raw Backend Response:", output);

    // ✅ Clean and remove unwanted characters
    const sanitizedOutput = output.replace(/[^\x20-\x7E\n]/g, "").trim();
    console.log("📌 Sanitized Output:", sanitizedOutput);

    return testCases.map((test, index) => {
      let expectedOutput = test.expectedOutput
        ? test.expectedOutput
        : "Missing Expected Output";
      let input = test.inputs ? JSON.stringify(test.inputs) : "Unknown Input";

      // ✅ Updated regex pattern to match any output type
      const regexPattern = new RegExp(`Test ${index + 1} Output:\\s*(.*)`);
      const match = regexPattern.exec(sanitizedOutput);

      let actualOutput = "Error: No Output Found";
      if (match && match[1]) {
        actualOutput = match[1].trim();
      }

      // ✅ Normalize outputs (handle booleans, numbers, strings)
      const normalizeOutput = (str) =>
        str
          .replace(/'/g, '"') // Convert single quotes to double quotes
          .replace(/\s*,\s*/g, ",") // Remove spaces around commas
          .trim();

      const normalizedExpectedOutput = normalizeOutput(expectedOutput);
      const normalizedActualOutput = normalizeOutput(actualOutput);

      const passed = normalizedActualOutput === normalizedExpectedOutput;

      console.log(
        `📌 Test ${index + 1}: Expected = ${normalizedExpectedOutput}, Actual = ${normalizedActualOutput}, Passed = ${passed}`
      );

      return {
        input,
        expectedOutput: normalizedExpectedOutput,
        actualOutput: normalizedActualOutput,
        passed,
      };
    });
  };

  const showTestResultsPopup = (testCaseResults, allPassed) => {
    let resultHTML = "<h3>Test Results</h3><ul style='text-align: left;'>";

    testCaseResults.forEach((test, index) => {
      resultHTML += `
          <li>
              <strong>Test ${index + 1}:</strong> ${test.passed ? "✅ Passed" : "❌ Failed"}<br/>
              <strong>Input:</strong> ${test.input}<br/>
              <strong>Expected:</strong> ${test.expectedOutput}<br/>
              <strong>Received:</strong> ${test.actualOutput}
          </li>
          <br/>
      `;
    });

    resultHTML += "</ul>";

    Swal.fire({
      title: allPassed ? "🎉 All Tests Passed!" : "⚠️ Some Tests Failed",
      html: resultHTML,
      icon: allPassed ? "success" : "error",
      confirmButtonText: "Okay",
    });
  };

  // // Check the solution
  // const checkSolution = async () => {
  //   const normalize = (code) =>
  //     code.replace(/\s+/g, " ").trim(); // Replace multiple spaces, tabs, and newlines with a single space and remove leading/trailing whitespace

  //   const { opening, closing } = extractFunctionStructure(
  //     solutions[activeSolutionIndex]?.solutionCode
  //   );

  //   // Generate the full user solution code for comparison
  //   const fullUserSolution = codeSnippet.includes(opening)
  //     ? codeSnippet.trim()
  //     : `${opening}\n${codeSnippet}\n${closing}`.trim();

  //   const normalizedUserSolution = normalize(fullUserSolution);
  //   console.log(normalizedUserSolution);

  //   // Step 1: Local match with solutionCode
  //   const matchedSolution = solutions.find(
  //     (solution) => normalize(solution.solutionCode) === normalizedUserSolution
  //   );

  //   const userId = localStorage.getItem("userId");
  //   const problemId = problem.id;
  //   const solutionId = matchedSolution?.id;
  //   const solutionLevel = level;
  //   const activeSolutionId = solutions[activeSolutionIndex]?.id;

  //   console.log("solutionId", solutionId);
  //   console.log("activeSolutionId", activeSolutionId);

  //   if (matchedSolution) {
  //     // Local match found
  //     handleCorrectSolution("local", matchedSolution); // Pass the matched solution

  //     if (userId) {
  //       try {
  //         const response = await axios.put(
  //           `${baseUrl}/progress/update/${userId}/${problemId}`,
  //           {
  //             completed: true,
  //             solutionId: solutionId,
  //             level: solutionLevel.toUpperCase(),
  //           }
  //         );
  //         if (response.status === 200) {
  //           console.log("UserProgress updated (local match):", response.data);
  //         } else {
  //           console.error(
  //             "Failed to update UserProgress (local match):",
  //             response.data
  //           );
  //         }
  //       } catch (error) {
  //         console.error("Error updating UserProgress (local match):", error);
  //       }
  //     }
  //   } else {
  //     // Step 2: Fallback to backend route for variation check
  //     try {
  //       const response = await axios.post(`${baseUrl}/problems/checkSolution`, {
  //         problemName: problem.title,
  //         codeSnippet: normalizedUserSolution,
  //         language: selectedLanguage, // Pass the selected language
  //       });

  //       const isCorrect = response.data.isCorrect;

  //       if (isCorrect) {
  //         handleCorrectSolution(
  //           "variation",
  //           solutions[activeSolutionIndex] || {}
  //         ); // Pass the current solution

  //         if (userId) {
  //           try {
  //             const updateResponse = await axios.put(
  //               `${baseUrl}/progress/update/${userId}/${problemId}`,
  //               {
  //                 completed: true,
  //                 solutionId: activeSolutionId || null,
  //                 level: solutionLevel.toUpperCase(),
  //               }
  //             );
  //             if (updateResponse.status === 200) {
  //               console.log(
  //                 "UserProgress updated (variation check):",
  //                 updateResponse.data
  //               );
  //             } else {
  //               console.error(
  //                 "Failed to update UserProgress (variation check):",
  //                 updateResponse.data
  //               );
  //             }
  //           } catch (error) {
  //             console.error(
  //               "Error updating UserProgress (variation check):",
  //               error
  //             );
  //           }
  //         }
  //       } else {
  //         handleIncorrectSolution(); // Solution is incorrect

  //         if (userId) {
  //           try {
  //             const incorrectResponse = await axios.put(
  //               `${baseUrl}/progress/update/${userId}/${problemId}`,
  //               {
  //                 completed: false,
  //                 solutionId: activeSolutionId || null,
  //                 level: solutionLevel.toUpperCase(),
  //               }
  //             );

  //             if (incorrectResponse.status === 200) {
  //               console.log(
  //                 "Updated attempts for incorrect solution: ",
  //                 incorrectResponse.data
  //               );
  //             } else {
  //               console.error(
  //                 "Failed to update after incorrect: ",
  //                 incorrectResponse.data
  //               );
  //             }
  //           } catch (incorrectError) {
  //             console.error("Error updating after incorrect: ", incorrectError);
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error checking solution via backend:", error);
  //       Swal.fire({
  //         title: "Error",
  //         text: "There was an issue checking your solution. Please try again later.",
  //         icon: "error",
  //         confirmButtonText: "Okay",
  //       });
  //     }
  //   }
  // };

  const handleCorrectSolution = async (type, solution = {}) => {
    // 🎉 Fire confetti
    launchConfetti();
    // Resize the blocks section after solving correctly
    setTimeout(() => {
      setSplitSizes([35, 65]);
    }, 1000);

    const optimalSolution = solutions.find((sol) => sol.isOptimal);
    const isBeginnerLevel = level === "Beginner";
    const isProLevel = level === "Pro";
    const isIntermediateOrExpert =
      level === "Intermediate" || level === "Expert";

    const solutionId = solution?.id || solutions[activeSolutionIndex]?.id;

    // ✅ Update progress depending on level
    if (updateProgress) {
      const normalizedLevel = level.toUpperCase();

      if (isBeginnerLevel || isProLevel) {
        updateProgress({ [normalizedLevel]: true });
      } else if (isIntermediateOrExpert && solutionId) {
        updateProgress((prev) => {
          const current = prev?.[normalizedLevel] || {
            count: 0,
            solutionIds: [],
          };
          return {
            [normalizedLevel]: {
              count: current.count + 1,
              solutionIds: [...new Set([...current.solutionIds, solutionId])],
            },
          };
        });
      }
    }

    // // 🎉 Success modal
    // await Swal.fire({
    //   title: "🎉 Great job!",
    //   text: "Your solution is correct.",
    //   icon: "success",
    //   confirmButtonText: "Keep Going",
    // });

    // Stats logging
    const isOptimal = isBeginnerLevel
      ? true
      : solutions[activeSolutionIndex]?.isOptimal || false;

    if (isOptimal) {
      console.log("User solved with optimal solution");
    } else {
      console.log("User solved with non-optimal solution");
    }
  };

  // // Handle correct solutions
  // const handleCorrectSolution = async (type, solution = {}) => {
  //   console.log(solution);
  //   const isOptimal = solution?.isOptimal || false;
  //   const timeComplexity = solution?.timeComplexity || "Unknown";
  //   const spaceComplexity = solution?.spaceComplexity || "Unknown";

  //   let title = "Good job!";
  //   let message = `
  //     <p>Your solution is correct.</p>
  //   `;

  //   if (level === "Beginner") {
  //     // Beginner expects only the optimal solution
  //     title = "Congrats!";
  //     message = `
  //       <p>You found the <strong>optimal solution</strong>!</p>
  //     `;

  //     // Show Beginner-specific buttons
  //     Swal.fire({
  //       title,
  //       html: `
  //         ${message}
  //         <div style="margin-top: 10px; text-align: left;">
  //           <p><strong>Time Complexity:</strong> <span style="color: #1abc9c;">${timeComplexity}</span></p>
  //           <p><strong>Space Complexity:</strong> <span style="color: #3498db;">${spaceComplexity}</span></p>
  //         </div>
  //       `,
  //       icon: "success",
  //       showDenyButton: true,
  //       confirmButtonText: "Try on Intermediate",
  //       denyButtonText: "Next Problem",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         // Switch to Intermediate level
  //         setSolutionBlocksByLevel((prev) => ({
  //           ...prev,
  //           Beginner: solutionBlocks,
  //         }));
  //         setBlocksByLevel((prev) => ({
  //           ...prev,
  //           Beginner: blocks,
  //         }));
  //         handleLevelChange("Intermediate");
  //       } else if (result.isDenied) {
  //         // Load the next problem in Beginner level
  //         navigateToNextProblem("Beginner");
  //       }
  //     });

  //     setFeedbackMessage("Success! The solution is correct.");
  //     confetti({
  //       particleCount: 100,
  //       spread: 70,
  //       origin: { x: 0.5, y: 0.65 },
  //       zIndex: 9999,
  //     });

  //     return; // Exit the function for Beginner-specific logic
  //   }

  //   // Intermediate & Expert Level Logic
  //   if (level === "Intermediate" || level === "Expert") {
  //     console.log(activeSolutionIndex);
  //     if (isOptimal) {
  //       title = "Congrats!";
  //       message = `
  //         <p>You found the <strong>optimal solution</strong>!</p>
  //       `;
  //     } else {
  //       title = "Room for improvement!";
  //       message = `
  //         <p>You found a solution but it is <strong>not optimal</strong>.</p>
  //         <p>Try to optimize your code.</p>
  //       `;
  //     }

  //     message += `
  //       <div style="margin-top: 10px; text-align: left;">
  //         <p><strong>Time Complexity:</strong> <span style="color: #1abc9c;">${timeComplexity}</span></p>
  //         <p><strong>Space Complexity:</strong> <span style="color: #3498db;">${spaceComplexity}</span></p>
  //       </div>
  //     `;

  //     // Show the Swal popup with appropriate messages
  //     Swal.fire({
  //       title,
  //       html: message,
  //       icon: isOptimal ? "success" : "info",
  //       showDenyButton: true, // Add a second button
  //       confirmButtonText: "Next Solution",
  //       denyButtonText: "Next Problem",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         if (level === "Intermediate" || level === "Expert") {
  //           // Handle Intermediate solution progression
  //           const solutionKey = `${level}-${activeSolutionIndex}`;
  //           setSolutionBlocksBySolution((prev) => ({
  //             ...prev,
  //             [solutionKey]: solutionBlocks,
  //           }));
  //           setBlocksBySolution((prev) => ({
  //             ...prev,
  //             [solutionKey]: blocks,
  //           }));

  //           // Move to the next solution
  //           const nextSolutionIndex =
  //             (activeSolutionIndex + 1) % solutions.length;
  //           setActiveSolutionIndex(nextSolutionIndex);

  //           const nextSolutionKey = `${level}-${nextSolutionIndex}`;
  //           setSolutionBlocks(solutionBlocksBySolution[nextSolutionKey] || []);
  //           setBlocks(
  //             blocksBySolution[nextSolutionKey] ||
  //               solutions[nextSolutionIndex]?.blocks.filter(
  //                 (block) =>
  //                   block.level ===
  //                   (solutions[nextSolutionIndex].isOptimal
  //                     ? "BEGINNER"
  //                     : "INTERMEDIATE")
  //               ) ||
  //               []
  //           );
  //         } else if (level === "Expert") {
  //           // Navigate to the next level or implement Expert-specific behavior
  //           handleLevelChange("Pro"); // Example for Pro level
  //         }
  //       } else if (result.isDenied) {
  //         navigateToNextProblem(level); // Load the next problem
  //       }
  //     });

  //     setFeedbackMessage("Success! The solution is correct.");
  //     confetti({
  //       particleCount: 100,
  //       spread: 70,
  //       origin: { x: 0.5, y: 0.65 },
  //       zIndex: 9999,
  //     });

  //     return;
  //   }

  //   // Pro Level Logic
  //   if (level === "Pro") {
  //     try {
  //       // Call the backend route to evaluate the solution
  //       const response = await axios.post(
  //         `${baseUrl}/problems/evaluateProSolution`,
  //         {
  //           solutionCode: codeSnippet,
  //           problemName: problem.title,
  //           language: selectedLanguage,
  //         }
  //       );

  //       const { isOptimal, timeComplexity, spaceComplexity } = response.data;

  //       Swal.fire({
  //         title: `Solution Evaluation`,
  //         html: `
  //           <p>Your solution is ${isOptimal ? "optimal" : "not optimal"}.</p>
  //           <div style="margin-top: 10px; text-align: left;">
  //             <p><strong>Time Complexity:</strong> <span style="color: #1abc9c;">${timeComplexity}</span></p>
  //             <p><strong>Space Complexity:</strong> <span style="color: #3498db;">${spaceComplexity}</span></p>
  //           </div>
  //         `,
  //         icon: isOptimal ? "success" : "info",
  //         confirmButtonText: "Next Problem",
  //       }).then(() => {
  //         navigateToNextProblem("Pro");
  //       });

  //       setFeedbackMessage(
  //         `Success! Your solution is ${isOptimal ? "optimal" : "not optimal"}.`
  //       );
  //       confetti({
  //         particleCount: 100,
  //         spread: 70,
  //         origin: { x: 0.5, y: 0.65 },
  //         zIndex: 9999,
  //       });
  //     } catch (error) {
  //       console.error("Error evaluating solution:", error);
  //       Swal.fire({
  //         title: "Error",
  //         text: "Failed to evaluate your solution. Please try again later.",
  //         icon: "error",
  //         confirmButtonText: "OK",
  //       });
  //     }
  //   }
  // };

  // Handle incorrect solutions
  const handleIncorrectSolution = () => {
    // Swal.fire({
    //   title: "Try Again",
    //   text: "Your solution is not correct. Keep trying!",
    //   icon: "error",
    //   confirmButtonText: "Got it",
    // });

    setShowHintButton(true); // Show the button
    setHintUsed(false); // Reset hint usage if trying again
    setShakeEditor(true);
    setShowErrorToast(true);

    setTimeout(() => {
      setTestCaseSizes([30, 70]);
    }, 1500);

    setTimeout(() => {
      setShakeEditor(false);
      setShowErrorToast(false);
    }, 4000);
  };

  const getDifficultyStyle = (difficulty) => {
    switch (difficulty) {
      case "EASY":
        return "difficulty easy";
      case "MEDIUM":
        return "difficulty medium";
      case "HARD":
        return "difficulty hard";
      default:
        return "difficulty";
    }
  };

  const codeMirrorValue = useMemo(() => {
    if (level === "Pro") {
      return `${functionStructure.opening}\n${codeSnippet}`;
    } else {
      return generateDynamicCode(
        solutions[activeSolutionIndex]?.solutionCode,
        solutionBlocks
      );
    }
  }, [level, solutions, activeSolutionIndex, codeSnippet, solutionBlocks]);

  return (
    <div className={`drag-container ${shakeEditor ? "shake" : ""}`}>
      <div className="level-switcher">
        {/* <LevelSwitcher
          level={level}
          handleLevelChange={handleLevelChange}
          renderSolutionTabs={renderSolutionTabs}
        /> */}
      </div>
      <Split
        className="split"
        sizes={[25, 75]} // Initial sizes: 20% left, 80% right
        minSize={[200, 300]} // Left pane can be hidden, right min width is 300px
        gutterSize={10} // Width of the gutter
        snapOffset={10} // Snaps when resizing
        expandToMin={false}
      >
        <div className="left-pane">
          <ProblemSection
            problem={problem}
            level={level}
            handleLevelChange={handleLevelChange}
            user={user}
            // renderSolutionTabs={renderSolutionTabs}
          />
        </div>
        <div className="right-pane">
          <Split
            className="horizontal-split"
            sizes={level === "Pro" ? [0, 100] : [50, 50]} // Collapse left pane in Code Mode
            minSize={[level === "Pro" ? 0 : 200, 300]} // Set min size conditionally
          >
            <motion.div
              className="left-section"
              initial={{ width: "25%" }}
              animate={{ width: level === "Pro" ? "0%" : "75%" }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              style={{ overflow: "hidden" }} // Hide overflow when closed
            >
              <Split
                className="blocks-split"
                direction="vertical"
                sizes={splitSizes}
                onDragEnd={(sizes) => setSplitSizes(sizes)}
                // sizes={[40, 60]}
                // minSize={[100, 100]}
                // height="auto"
              >
                <div className="solution-section" style={{ height: "auto" }}>
                  <SolutionSection
                    showCompletionModal={showCompletionModal}
                    setShowCompletionModal={setShowCompletionModal}
                    level={level}
                    handleLevelChange={handleLevelChange}
                    solutionBlocks={solutionBlocks}
                    renderBlock={renderBlock}
                    removeFromSolution={removeFromSolution}
                    handleAddBlockInside={handleAddBlockInside}
                    blocks={blocks}
                    moveBlockUp={moveBlockUp}
                    moveBlockDown={moveBlockDown}
                    showHintButton={showHintButton}
                    fetchHint={fetchHint}
                    getBlockStyle={getBlockStyle}
                    solutionCompleted={solutionCompleted}
                    solutionName={activeSolutionName}
                  />
                </div>
                <div className="blocks-section">
                  {/* <LevelSwitcher 
                    level={level}
                    handleLevelChange={handleLevelChange}
                    renderSolutionTabs={renderSolutionTabs}
                  /> */}

                  {/* Render the "Reveal Blocks" button for Pro level */}
                  {/* {level === "Pro" && !areBlocksVisible && (
                    <button
                      className="reveal-blocks-button"
                      onClick={handleRevealBlocks}
                    >
                      Reveal Blocks
                    </button>
                  )} */}

                  {/* Render level switcher here */}
                  {/* {renderLevelSwitcher()} */}
                  <BlocksSection
                    areBlocksVisible={areBlocksVisible}
                    blocks={blocks}
                    blockHighlightClass={blockHighlightClass}
                    highlightedBlock={highlightedBlock}
                    addToSolution={addToSolution}
                    level={level}
                    handleLevelChange={handleLevelChange}
                    showHintButton={showHintButton}
                    hintUsed={hintUsed}
                    solutionCompleted={solutionCompleted}
                    solutions={solutions}
                    activeSolutionIndex={activeSolutionIndex}
                    problem={problem}
                    codeSnippet={codeSnippet}
                    onHintClick={() => {
                      fetchHint();
                      setHintUsed(true);
                    }}
                    // renderSolutionTabs={renderSolutionTabs}
                  />
                </div>
              </Split>
            </motion.div>
            <motion.div
              className="right-section"
              initial={{ width: "75%" }}
              animate={{ width: level === "Pro" ? "100%" : "75%" }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
            >
              <Split
                className="code-output-split"
                direction="vertical"
                sizes={testCaseSizes}
                onDragEnd={(sizes) => setTestCaseSizes(sizes)}
                // maxSize={[500, 500]}
                minSize={[100, 100]}
              >
                <div className={`code-section`}>
                  <CodeEditor
                    level={level}
                    generateDynamicCode={generateDynamicCode}
                    solutions={solutions}
                    activeSolutionIndex={activeSolutionIndex}
                    solutionBlocks={solutionBlocks}
                    options={options}
                    codeSnippet={codeSnippet}
                    setCodeSnippet={setCodeSnippet}
                    // setProCodeSnippet={setProCodeSnippet}
                    selectedText={selectedText}
                    buttonPosition={buttonPosition}
                    handleWhatDoesThisMean={handleWhatDoesThisMean}
                    checkSolution={checkSolution}
                    handleSelectionChange={handleSelectionChange}
                    problem={problem}
                    selectedLanguage={selectedLanguage}
                    extractFunctionStructure={extractFunctionStructure}
                  />
                </div>
                <div className="output-section">
                  <TestCases
                    testResults={testResults}
                    currentTestIndex={currentTestIndex}
                    setCurrentTestIndex={setCurrentTestIndex}
                    allTestsPassed={allTestsPassed}
                    feedbackMessage={feedbackMessage}
                    problem={problem}
                    isChecking={isChecking}
                  />
                </div>
              </Split>
            </motion.div>
          </Split>
        </div>
        {showErrorToast && (
          <div className="error-toast">
            ❌ Your solution is not correct. Keep trying!
          </div>
        )}
      </Split>

      {/* Modal/Dropdown for block selection when adding inside a container */}
      {isSelectingBlock && (
        <div
          className="block-selection-modal"
          style={{
            position: "absolute",
            top: "20%",
            left: "50%",
            background: "white",
            padding: "20px",
            height: "50vh",
            overflow: "auto",
            border: "1px solid black",
            zIndex: "2",
          }}
        >
          <h3>Select Block to Add Inside</h3>
          {blocks?.length > 0 ? (
            blocks?.map((block) => (
              <button
                key={block.id}
                onClick={() => addBlockToContainer(block.id)}
                className={`block-button ${block.type || "default"}`} // Dynamically assign class
                style={{ margin: "5px" }}
              >
                {block.content}
              </button>
            ))
          ) : (
            <p>No blocks available to add</p>
          )}
        </div>
      )}
    </div>
  );
};

export default DragAndDropWorkspace;
