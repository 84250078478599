import React, { useState, useEffect, useRef } from "react"; // ⬅️ add useRef
import axios from "axios";
import LoginModal from "./LoginModal";
import LogoutButton from "./LogoutButton";
import { Link, useLocation } from "react-router-dom"; // Import useLocation

import "./NavBarV2.css";

const NavBarV2 = () => {
  const [user, setUser] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation(); // Get the current page
  const isEntryPage = location.pathname === "/entry"; // Check if on Entry page

  const loginButtonRef = useRef(null);

  useEffect(() => {
    // Make it available globally ONLY when on Entry page
    if (isEntryPage) {
      window.loginButtonRef = loginButtonRef;
    } else {
      window.loginButtonRef = null;
    }
  }, [isEntryPage]);

  useEffect(() => {
    const fetchUser = async () => {
      const accessToken = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");

      if (!accessToken || !userId) {
        console.log("No user logged in.");
        return;
      }
      try {
        const response = await axios.get(`${baseUrl}/users/account/${userId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true,
        });
        setUser(response.data);
        console.log("Fetched user:", response.data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, [baseUrl, isLoggedIn]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    setIsLoggedIn(false);
  };

  const handleLogin = (userData) => {
    localStorage.setItem("token", userData.token);
    localStorage.setItem("userId", userData.userId);
    setUser(userData);
    setIsLoggedIn(true);
    setShowLoginModal(false);
  };

  return (
    <nav className="navbarv2">
      <div className="navbar-brand">
        <Link
          to={localStorage.getItem("token") ? "/" : "/entry"}
          className="brand"
          onClick={() => window.scrollTo(0, 0)}
        >
          BlockCode
        </Link>

        {/* Hide these links if on Entry page */}
        {!isEntryPage && (
          <>
            <Link to="/problems" className="menu-item">
              Problems
            </Link>
            <Link to="/create-problem" className="menu-item">
              Import Problem
            </Link>
            <Link to="/membership" className="menu-item">
              Membership
            </Link>
          </>
        )}
      </div>

      <div className="navbar-actions">
        {user ? (
          <>
            <span className="navbar-user">Hello, {user.username}</span>
            <Link to="/account" className="menu-item">
              Account
            </Link>
            <LogoutButton onLogout={handleLogout} />
          </>
        ) : (
          <>
            <button
              ref={loginButtonRef}
              className="login-button"
              onClick={() => setShowLoginModal(true)}
            >
              Login
            </button>
          </>
        )}
      </div>

      {showLoginModal && (
        <LoginModal
          onLogin={handleLogin}
          onClose={() => setShowLoginModal(false)}
        />
      )}
    </nav>
  );
};

export default NavBarV2;
