import React, { useEffect, useRef, useState } from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css"; // Import styles
import "codemirror/mode/javascript/javascript"; // Import language mode
import "./CodeEditor.css";

const CodeEditor = ({
  level,
  generateDynamicCode,
  solutions,
  activeSolutionIndex,
  solutionBlocks,
  options,
  codeSnippet,
  setCodeSnippet,
  selectedText,
  buttonPosition,
  handleWhatDoesThisMean,
  checkSolution,
  handleSelectionChange,
  problem,
  selectedLanguage
}) => {
  const editorRef = useRef(null);
  const [loadedProCode, setLoadedProCode] = useState("");
  const isInitialized = useRef(false); // ✅ Prevents premature onChange execution

  useEffect(() => {
    if (level === "Pro" && problem) {
      const proStorageKey = `pro-code-${problem.id}-${selectedLanguage}`;
      const savedCode = localStorage.getItem(proStorageKey);

      if (savedCode !== null) {
        setLoadedProCode(savedCode);
        setCodeSnippet(savedCode);
      } else {
        const boilerplate = generateDynamicCode(
          solutions[activeSolutionIndex]?.solutionCode,
          []
        );
        setLoadedProCode(boilerplate);
        setCodeSnippet(boilerplate);
        localStorage.setItem(proStorageKey, boilerplate);
      }

      isInitialized.current = true; // ✅ Mark as initialized
    }
  }, [level, problem?.id, selectedLanguage, solutions, activeSolutionIndex]);

  return (
    <>
      <div className="code-editor-sticky">
        <h2 className="code-snippet-header">Code</h2>
        <div>
          <button className="code-button" onClick={checkSolution}>Run Code</button>
        </div>
      </div>

      <div className="code-mirror-settings">
        <CodeMirror
          key={level === "Pro" ? `pro-${problem.id}-${selectedLanguage}` : `nonpro-${problem.id}-${selectedLanguage}`}
          value={level === "Pro" ? loadedProCode : generateDynamicCode(
                  solutions[activeSolutionIndex]?.solutionCode,
                  solutionBlocks
                )}
          options={options}
          editorDidMount={(editor) => {
            editorRef.current = editor;
          }}
          onSelection={(editor) => handleSelectionChange(editor)}
          onChange={(editor, data, value) => {
            if (!isInitialized.current) return; // ✅ Ignore early onChange calls

            if (level === "Pro") {
              console.log("🔥 Saving to localStorage:", value); // Track what is being saved

              const cursor = editor.getCursor();
              setCodeSnippet(value);
              localStorage.setItem(`pro-code-${problem.id}-${selectedLanguage}`, value);



              setTimeout(() => editor.setCursor(cursor), 0);
            } else {
              setCodeSnippet(value);
            }
          }}
        />

        {selectedText.trim() && (
          <button
            onClick={handleWhatDoesThisMean}
            style={{
              position: "absolute",
              top: buttonPosition.y,
              left: buttonPosition.x,
              padding: "8px 12px",
              backgroundColor: "#3498db",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              zIndex: 1000,
            }}
          >
            What does this mean?
          </button>
        )}
      </div>
    </>
  );
};

export default CodeEditor;
