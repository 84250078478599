import React from "react";
import { Navigate } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ProblemPage from "./pages/ProblemPage";
import ProblemsList from "./pages/ProblemsList";
import Register from "./components/Register";
import AddProblemPage from "./pages/AddProblemPage";
import AccountPage from "./pages/AccountPage";
import NavBarV2 from "./components/NavBarV2";
import ContactUs from "./components/ContactUs";
import BottomNavBar from "./components/BottomNavBar";
import ProblemOfTheDay from "./pages/ProblemOfTheDay";
import SurveyForm from "./components/SurveyForm";
import Entry from "./components/Entry";
import Membership from "./pages/Membership";
import NewProblemPage from "./pages/NewProblemPage";
import "./App.css";
import LearningPaths from "./pages/LearningPaths";
import LearningPathPage from "./pages/LearningPathPage";
import ProtectedRoute from "./components/ProtectedRoute";

const HomeWrapper = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(null);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token); // convert to true/false
  }, []);

  if (isLoggedIn === null) return null; // or a loading spinner

  return isLoggedIn ? <HomePage /> : <Navigate to="/entry" replace />;
};

function App() {
  return (
    <Router>
      <div className="app-container">
        {/* 🎉 Confetti Canvas - Always Available */}
        <canvas
          id="confetti-canvas"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            zIndex: 9999, // Above Swal
          }}
        />

        <NavBarV2 />

        <div className="content-container">
          <Routes>
            <Route path="/" element={<HomeWrapper />} />
            // <Route path="/" element={<HomePage />} />
            <Route path="/problems" element={<ProblemsList />} />
            <Route
              path="/create-problem"
              element={
                <ProtectedRoute allowedRoles={["PAID_USER", "ADMIN"]}>
                  <AddProblemPage />
                </ProtectedRoute>
              }
            />
            <Route path="/problems/:id" element={<ProblemPage />} />
            <Route path="/register" element={<Register />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/problem-of-the-day" element={<ProblemOfTheDay />} />
            <Route path="/survey" element={<SurveyForm />} />
            <Route path="/entry" element={<Entry />} />
            <Route path="/membership" element={<Membership />} />
            <Route path="/problems2/:id" element={<NewProblemPage />} />
            <Route path="/learning-paths" element={<LearningPaths />} />
            <Route
              path="/learning-paths/:pathId"
              element={<LearningPathPage />}
            />
          </Routes>
        </div>

        {/* <BottomNavBar /> */}
      </div>
    </Router>
  );
}

export default App;
