import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners"; // Import spinner from react-spinners
import CopyPasteProblem from "../components/CopyPasteProblem";
import AddProblemByName from "../components/AddProblemByName";
import NavBarV2 from "../components/NavBarV2";

const AddProblemPage = () => {


  return (
    <div style={{ padding: "20px" }}>
      <CopyPasteProblem />
    </div>
  );
};

export default AddProblemPage;
