import React, { useState } from "react";
import "./ContactUs.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    reason: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setIsSubmitting(true);

    try {
      const response = await fetch("http://localhost:5000/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Your message was sent successfully!");
        setFormData({
          name: "",
          email: "",
          reason: "",
          message: "",
        }); // Reset the form
      } else {
        alert("Failed to send your message. Please try again.");
      }
    } catch (error) {
      console.error("Error sending form data:", error);
      alert("An error occurred while sending your message.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-container">
      <h1 className="contact-heading">Contact Us</h1>
      <form
        onSubmit={handleSubmit}
        className="contact-form"
      >
        {/* Name Input */}
        <label className="contact-label" htmlFor="name">
          Name:
        </label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Your name"
          value={formData.name}
          onChange={handleInputChange}
          required
          className="contact-input"
        />

        {/* Email Input */}
        <label className="contact-label" htmlFor="email">
          Email:
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Your email"
          value={formData.email}
          onChange={handleInputChange}
          required
          className="contact-input"
        />

        {/* Reason for Contact Dropdown */}
        <label className="contact-label" htmlFor="reason">
          Reason for Contact:
        </label>
        <select
          id="reason"
          name="reason"
          value={formData.reason}
          onChange={handleInputChange}
          required
          className="contact-dropdown"
        >
          <option value="">-- Select an option --</option>
          <option value="general-inquiry">General Inquiry</option>
          <option value="subscription-issues">Subscription Issues</option>
          <option value="problem-issues">Import a Problem Issues</option>
          <option value="licensing-inquiry">Licensing Inquiry</option>
        </select>

        {/* Description Box */}
        <label className="contact-label" htmlFor="message">
          Description:
        </label>
        <textarea
          id="message"
          name="message"
          placeholder="Type your message here"
          value={formData.message}
          onChange={handleInputChange}
          required
          className="contact-textarea"
        ></textarea>

        {/* Submit Button */}
        <button
          type="submit"
          className="contact-button"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Sending..." : "Send Message"}
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
