import React, { useState } from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners"; // Import spinner from react-spinners
import "./CopyPasteProblem.css";
import LoadingScreen from "./LoadingScreen";
import { toast } from "react-toastify";

const CopyPasteProblem = () => {
  const [userInput, setUserInput] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("javascript"); // Default language
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Use navigate for redirection
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const languages = [
    { value: "javascript", label: "JavaScript" },
    // { value: "python", label: "Python" },
    { value: "java", label: "Java" },
    // { value: "csharp", label: "C#" },
    // { value: "cpp", label: "C++" },
    // { value: "swift", label: "Swift" },
  ];

  const handleUserInputChange = (e) => setUserInput(e.target.value);

  const handleLanguageChange = (e) => setSelectedLanguage(e.target.value);

  const handleGenerateBlocksFromInput = async () => {
    const toastId = toast.loading(
      "Creating your problem... This may take a minute.",
      { position: "bottom-right", autoClose: false, draggable: false }
    );

    // Get the userId and token from local storage
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    if (!userId || !token) {
      toast.update(toastId, {
        render: "User is not logged in. Please log in to add a problem.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Generate problem data from input
      const response = await axios.post(
        `${baseUrl}/problems/generate-from-copy-paste`,
        {
          userInput,
          language: selectedLanguage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the headers
          },
        }
      );

      const generatedProblemData = response.data;

      // Provide default values if missing
      const validCategories = [
        "ARRAYS",
        "STRINGS",
        "GRAPHS",
        "DYNAMIC_PROGRAMMING",
      ];
      const validDifficulties = ["EASY", "MEDIUM", "HARD"];

      generatedProblemData.category = validCategories.includes(
        generatedProblemData.category
      )
        ? generatedProblemData.category
        : "ARRAYS";
      generatedProblemData.difficulty = validDifficulties.includes(
        generatedProblemData.difficulty
      )
        ? generatedProblemData.difficulty
        : "MEDIUM";

      generatedProblemData.input = generatedProblemData.input || " ";

      // Add userId to generatedProblemData
      generatedProblemData.userId = userId;

      generatedProblemData.inputVars = generatedProblemData.inputVars || " ";
      generatedProblemData.returnType =
        generatedProblemData.returnType || "Unknown";
      generatedProblemData.testCases = generatedProblemData.testCases || [];

      console.log("generated problem data", generatedProblemData);

      // Save the problem in the database
      const addResponse = await axios.post(
        `${baseUrl}/problems/add`,
        generatedProblemData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the headers
          },
        }
      );

      const newProblemId = addResponse.data.id;

      toast.update(toastId, {
        render: (
          <div>
            <p>Your problem is ready to go!</p>
            <button
              style={{
                backgroundColor: "#007bff",
                color: "#ffffff",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
                cursor: "pointer",
                fontWeight: "bold",
                marginTop: "10px",
              }}
              onClick={() => {
                navigate(`/problems/${newProblemId}`, {
                  state: { selectedLanguage },
                });
                toast.dismiss(toastId);
              }}
            >
              Solve Problem Now!
            </button>
          </div>
        ),
        type: "success",
        isLoading: false,
        autoClose: false,
      });
    } catch (err) {
      console.error("Error generating and adding problem:", err);

      toast.update(toastId, {
        render:
          "Failed to generate and add the problem. Please try again later.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });

      setError("Failed to generate and add the problem");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-prob">
      <h2 className="copy-paste-header">
        What problem are you having trouble with?
      </h2>
      <textarea
        className="copy-paste-area"
        type="text"
        rows={20}
        cols={100}
        value={userInput}
        onChange={handleUserInputChange}
        placeholder="Copy and Paste problem info here"
        required
      />
      <br />
      <br />
      <label htmlFor="language-select">Select Language:</label>
      <select
        id="language-select"
        value={selectedLanguage}
        onChange={handleLanguageChange}
        style={{ margin: "10px 0", padding: "5px" }}
      >
        {languages.map((lang) => (
          <option key={lang.value} value={lang.value}>
            {lang.label}
          </option>
        ))}
      </select>
      <br />
      <button type="button" onClick={handleGenerateBlocksFromInput}>
        Generate Blocks
      </button>

      {/* Display loading spinner when loading */}
      {loading && (
        <div className="loading-container">
          {/* <ClipLoader color="#123abc" loading={loading} size={50} /> */}
          {loading && <LoadingScreen />}
          <p>Loading... Please wait while we add your problem.</p>
        </div>
      )}

      {/* Display error message if there's an error */}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default CopyPasteProblem;
