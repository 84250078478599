import React, { useState } from "react";
import "./Membership.css";

const tiers = [
  {
    name: "Free Tier User",
    description: "Great for getting started with coding challenges.",
    features: [
      "Access to 10-20 common problems",
      "Can only check one solution per day",
      "Cannot save progress or track XP",
      "Cannot use hints or 'What does this mean'",
      "No access to importing a problem",
      "Ads or limited features prompting users to upgrade?",
    ],
  },
  {
    name: "Basic Tier (Paid)",
    description: "Unlocks more features for consistent learners.",
    features: [
      "Access to all problems",
      "Can save progress and track XP",
      "Limited daily XP gain (50 XP max)",
      "Basic gamification (badges, progress tracking)",
      "Can import one problem per day",
      "Can use hints at a cost (lose XP or limited hints per day)",
      "No access to 'What does this mean'",
      "No ads",
      "Access to a community forum or Discord group",
      "Some customization options for themes, fonts, or layouts",
    ],
  },
  {
    name: "Premium Tier (Paid)",
    description: "For serious coders looking for advanced features.",
    recommended: true,
    features: [
      "Access to all problems",
      "Can create custom problems and share them with others",
      "Unlimited XP gain",
      "Advanced analytics (time spent per problem, strengths/weaknesses)",
      "Exclusive problem sets (real-world coding interview simulations)",
      "Access to all hints and 'What does this mean'",
      "Personalized coaching or feedback (if implemented)",
      "Higher customization options for themes, fonts, or layouts",
    ],
  },
];

const featureComparison = [
  { feature: "Access to all problems", free: false, basic: true, premium: true },
  { feature: "Save progress & track XP", free: false, basic: true, premium: true },
  { feature: "Unlimited XP gain", free: false, basic: false, premium: true },
  { feature: "Use hints", free: false, basic: "Limited", premium: true },
  { feature: "Import problems", free: false, basic: "1 per day", premium: "Unlimited" },
  { feature: "Advanced analytics", free: false, basic: false, premium: true },
  { feature: "Customization options", free: false, basic: "Basic", premium: "Advanced" },
  { feature: "No ads", free: false, basic: true, premium: true },
];

const Membership = () => {
    const [selectedTier, setSelectedTier] = useState("Free Tier User");

  const handleUpgrade = (tierName) => {
    setSelectedTier(tierName);
    alert(`You have selected the ${tierName} plan.`);
  };
  return (
    <div className="membership-container">
      <h1 className="membership-title">Choose Your Membership Plan</h1>
      <div className="membership-grid">
        {tiers.map((tier, index) => (
          <div key={index} className={`membership-card ${tier.recommended ? "recommended" : ""}`}>
            {tier.recommended && <div className="recommended-badge">Recommended</div>}
            <div className="card-content">
              <h2 className="tier-name">{tier.name}</h2>
              <p className="tier-description">{tier.description}</p>
              <ul className="membership-features">
                {tier.features.map((feature, idx) => (
                  <li key={idx} className="feature-item">{feature}</li>
                ))}
              </ul>
            </div>
            <button
              className={`upgrade-button ${selectedTier === tier.name ? "selected" : ""}`}
              onClick={() => handleUpgrade(tier.name)}
              disabled={tier.default && selectedTier === tier.name}
            >
              {tier.default ? "Current Plan" : selectedTier === tier.name ? "Selected" : "Upgrade"}
            </button>
          </div>
        ))}
      </div>

      <h2 className="membership-title">Feature Comparison</h2>
      <div className="membership-table-container">
        <table className="membership-table">
          <thead>
            <tr>
              <th>Feature</th>
              <th>Free</th>
              <th>Basic</th>
              <th>Premium</th>
            </tr>
          </thead>
          <tbody>
            {featureComparison.map((item, index) => (
              <tr key={index}>
                <td>{item.feature}</td>
                <td className={`text-center ${item.free ? "check" : "cross"}`}>{item.free === true ? "✔️" : item.free === false ? "❌" : item.free}</td>
                <td className={`text-center ${item.basic ? "check" : "cross"}`}>{item.basic === true ? "✔️" : item.basic === false ? "❌" : item.basic}</td>
                <td className={`text-center ${item.premium ? "check" : "cross"}`}>{item.premium === true ? "✔️" : item.premium === false ? "❌" : item.premium}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Membership;
