import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Split from "react-split";
import "./NewProblemPage.css";
import ProblemSection from "../components/ProblemSection";
import BeginnerBlocks from "../components/BeginnerBlocks";

const NewProblemPage = () => {
  const { id } = useParams(); // Get the problem ID from the route
  const location = useLocation(); // Access state from navigation
  const [problem, setProblem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(
    location.state?.level || "BEGINNER"
  );

  const [solutionBlocks, setSolutionBlocks] = useState([]); // State to track solution

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchProblem = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${baseUrl}/problems/${id}?level=${selectedLevel}`
        );
        setProblem(response.data);
      } catch (error) {
        console.error("Error fetching problem:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProblem();
  }, [id, selectedLevel]);

  // Function to add blocks to the solution section
  const addToSolution = (block) => {
    setSolutionBlocks((prevBlocks) => [...prevBlocks, block]);
  };

  return (
    <div className="new-container">
      <Split
        className="split"
        sizes={[30, 70]} // Initial sizes: 20% left, 80% right
        minSize={[0, 0]} // Left pane can be hidden, right min width is 300px
        gutterSize={10} // Width of the gutter
        snapOffset={10} // Snaps when resizing
      >
        <div className="left-pane">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ProblemSection problem={problem} level={selectedLevel} />
          )}
        </div>
        <div className="right-pane">
          <Split
            className="horizontal-split"
            sizes={[50, 50]}
            // maxSize={[700, 700]}
            minSize={[0,0]}
          >
            <div className="left-section">
              <Split
                className="blocks-split"
                direction="vertical"
                sizes={[60, 40]}
                // maxSize={[500, 500]}
                minSize={[0,0]}
              >
                 <div className="blocks-section">
                  <h3>Solution Blocks</h3>
                </div>
                <div className="blocks-section">
                  <h3>Blocks</h3>
                </div>
              </Split>
            </div>
            <div className="right-section">
              <Split
                className="code-output-split"
                direction="vertical"
                sizes={[60, 40]}
                // maxSize={[500, 500]}
                minSize={[0,0]}
              >
                <div className="code-section">Code Editor</div>
                <div className="output-section">Output Console</div>
              </Split>
            </div>
          </Split>
        </div>
      </Split>
    </div>
  );
};

export default NewProblemPage;
