import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Register.css";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/users/register`, {
        username,
        password,
        email,
        role,
      }, {
        withCredentials: true
      });

      console.log(response);
      if (response.data.id) {
        setLoading(false);
        setSuccess("Registration successful! This temporary account will be deleted once testing is complete.");
        setTimeout(() => {
          navigate("/");
        }, 10000);
      } else {
        throw new Error("Registration failed");
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(error.message);
    }
  };

  return (
    <div className="register-component-container">
      {error && <div className="error">{error}</div>}
      {loading && <div className="loading">Loading...</div>}
      {success && <div className="success">{success}</div>}
      <div className="register-header">
        <h1>Create Account</h1>
      </div>
      <form className="register-form" onSubmit={handleSubmit}>
        <div className="register-form-email">
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
        </div>
        <div className="register-form-username">
          <label>
            Username:
            <input
              type="text"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
        </div>
        <div className="register-form-password">
          <label>
            Password:
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
        </div>
        <div className="register-form-role">
          <label>
            Role:
            <select
              name="role"
              id="roleSelect"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="">--Please choose an option--</option>
              <option value="FREE_USER">Free User</option>
            </select>
          </label>
        </div>
        <div className="register-form-submit">
          <button type="submit" className="register-submit-button">
            Register
          </button>
        </div>
      </form>
    </div>
  );
};

export default Register;
