import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import DragAndDropWorkspace from "../components/DragAndDropWorkspace";
import { useNavigate } from "react-router-dom";
import "./ProblemPage.css";
import NavBarV2 from "../components/NavBarV2";
import BugReportForm from "../components/BugReportForm";
import LevelSwitcher from "../components/LevelSwitcher";

const ProblemPage = () => {
  const { id } = useParams(); // Get the problem ID from the route
  const location = useLocation(); // Access the state passed from navigate
  const [problem, setProblem] = useState(null);
  const [solutions, setSolutions] = useState([]); // State to store the solutions
  const [selectedLevel, setSelectedLevel] = useState(
    location.state?.level || "BEGINNER"
  ); // Default to 'BEGINNER' if not provided
  const [selectedLanguage, setSelectedLanguage] = useState(
    location.state?.selectedLanguage || "javascript" // Use passed language or default to JavaScript
  );
  const [mode, setMode] = useState(location.state?.mode || "blocks"); // Default to "blocks"
  const [activeSolutionIndex, setActiveSolutionIndex] = useState(0);
  const [progress, setProgress] = useState(location.state?.progress || null); // Use passed progress or initialize null
  const [loading, setLoading] = useState(false);
  const [isBugReportOpen, setIsBugReportOpen] = useState(false);
  const [user, setUser] = useState(null); // Add user state
  const [codeSnippet, setCodeSnippet] = useState("");
  const [blocks, setBlocks] = useState([]); // Block library
  const demoMode = location.state?.demoMode || false;
  const [solutionBlocks, setSolutionBlocks] = useState([]); // Solution section
  // const [mode, setMode] = useState("block");
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  // Helper function to capitalize the first letter
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const capitalizedLevel = capitalizeFirstLetter(selectedLevel); // Capitalize the level

  // Fetch problem and solutions
  useEffect(() => {
    const fetchProblemAndSolutions = async () => {
      setLoading(true);
      try {
        const problemResponse = await axios.get(
          `${baseUrl}/problems/${id}?level=${selectedLevel}&language=${selectedLanguage}`
        );
        setProblem(problemResponse.data);

        if (selectedLevel === "INTERMEDIATE" || selectedLevel === "EXPERT") {
          const solutionsResponse = await axios.get(
            `${baseUrl}/problems/${id}/solutions?language=${selectedLanguage}`
          );
          setSolutions(solutionsResponse.data);
        } else {
          const beginnerSolutions = problemResponse.data.solutions || [];

          // Find the index of the solution that has BEGINNER blocks
          const beginnerIndex = beginnerSolutions.findIndex((sol) =>
            sol.blocks?.some((block) => block.level === "BEGINNER")
          );

          // Set index if found
          if (beginnerIndex !== -1) {
            setActiveSolutionIndex(beginnerIndex);
          }

          setSolutions(beginnerSolutions);
        }

        console.log("Progress structure:", progress);
      } catch (error) {
        console.error("Error fetching problem or solutions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProblemAndSolutions();
  }, [id, selectedLanguage]);

  // Fetch logged-in user details
  useEffect(() => {
    const fetchUser = async () => {
      const accessToken = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");
      if (accessToken) {
        try {
          const response = await axios.get(
            `${baseUrl}/users/account/${userId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          setUser(response.data);
          console.log(response.data);
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      }
    };

    fetchUser();
  }, [baseUrl]);

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const isCompletionStatus = (level, solutionId = null) => {
    if (!progress) return false;

    const normalizedLevel = level.toUpperCase(); // <-- this is key
    const progressData = progress[normalizedLevel];

    if (normalizedLevel === "BEGINNER" || normalizedLevel === "PRO") {
      return progressData === true;
    }

    if (normalizedLevel === "INTERMEDIATE" || normalizedLevel === "EXPERT") {
      if (solutionId) {
        return progressData?.solutionIds?.includes(solutionId);
      }
      return false;
    }

    return false;
  };

  const solutionCompleted = useMemo(() => {
    return isCompletionStatus(
      selectedLevel,
      solutions[activeSolutionIndex]?.id
    );
  }, [selectedLevel, solutions, activeSolutionIndex, progress]);

  const renderSolutionTabs = () => {
    if (
      (selectedLevel === "Intermediate" || selectedLevel === "Expert") &&
      solutions.length > 1
    ) {
      const normalizedLevel = selectedLevel.toUpperCase();
      const completedSolutions = progress?.[normalizedLevel]?.solutionIds || [];

      return solutions.map((solution, index) => {
        const isSolved = completedSolutions.includes(solution.id);
        return (
          <button
            key={index}
            className={`solution-tab-dropdown ${
              index === activeSolutionIndex ? "active" : ""
            }`}
            onClick={() => setActiveSolutionIndex(index)}
          >
            {solution.name} {isSolved && <span className="checkmark">✅</span>}
          </button>
        );
      });
    }
    return null;
  };

  const handleLevelChange = (newLevel) => {
    console.log(`🔄 Switching from ${selectedLevel} to ${newLevel}`);

    // ✅ Save the current Pro code snippet before switching
    if (selectedLevel === "Pro") {
      const proStorageKey = `pro-code-${problem.id}-${selectedLanguage}`;
      console.log("📌 Saving latest Pro code before switching:", codeSnippet);

      localStorage.setItem(proStorageKey, codeSnippet);
    }

    // 🔍 Handle Beginner level: find the correct solution with beginner blocks
    if (newLevel === "Beginner") {
      const beginnerIndex = solutions.findIndex((sol) =>
        sol.blocks?.some((block) => block.level === "BEGINNER")
      );

      if (beginnerIndex !== -1) {
        setActiveSolutionIndex(beginnerIndex);
      } else {
        console.warn("No beginner solution found. Defaulting to index 0.");
        setActiveSolutionIndex(0);
      }
    }

    // Change the level
    setSelectedLevel(newLevel);

    // Storage key for retrieving saved data
    const storageKey = `solution-${problem.id}-${newLevel}-${activeSolutionIndex}-${selectedLanguage}`;
    const savedState = localStorage.getItem(storageKey);
    const parsedState = savedState ? JSON.parse(savedState) : null;
    // const proStorageKey = `pro-code-${problem.id}-${selectedLanguage}`;
    // const savedProCode = localStorage.getItem(proStorageKey) || "";

    if (newLevel === "Pro") {
      // No blocks should be shown in Pro level
      const proStorageKey = `pro-code-${problem.id}-${selectedLanguage}`;
      const savedProCode = localStorage.getItem(proStorageKey) || "";
      console.log("🚀 Loading Pro code after switch:", savedProCode);

      // setCodeSnippet(savedProCode);
    } else {
      // Restore saved state or reset to empty if not found
      setSolutionBlocks(parsedState?.solutionBlocks || []);
      setBlocks(parsedState?.blocks || []);
      setCodeSnippet(parsedState?.codeSnippet || "");
    }
  };

  const updateProgress = (newProgress) => {
    setProgress((prev) => {
      if (typeof newProgress === "function") {
        const result = newProgress(prev);
        return {
          ...prev,
          ...result,
        };
      } else {
        return {
          ...prev,
          ...newProgress,
        };
      }
    });
  };

  const activeSolutionName =
    solutions[activeSolutionIndex]?.name || "Solution Blocks";

  return (
    <div className="new-container">
      <div className="language-selector-container">
        {/* Left: Bug Report Button */}
        <div className="topbar-left">
          <button
            className="bug-report-button"
            onClick={() => setIsBugReportOpen(true)}
          >
            Report a Bug
          </button>
        </div>

        {/* Center: Level Switcher */}
        <div className="topbar-center">
          <LevelSwitcher
            level={selectedLevel}
            renderSolutionTabs={renderSolutionTabs}
            handleLevelChange={handleLevelChange}
            progress={progress}
            demoMode={demoMode}
          />
        </div>

        {/* Right: Language Selector */}
        <div className="topbar-right">
          <div className="language-selector">
            <label htmlFor="language">Language:</label>
            <select
              id="language"
              value={selectedLanguage}
              onChange={handleLanguageChange}
              disabled={loading}
            >
              <option value="javascript">JavaScript</option>
              {/* <option value="java">Java</option>
        <option value="python">Python</option>
        <option value="csharp">C#</option> */}
            </select>
          </div>
        </div>
      </div>

      {/* Conditional Bug Report Form */}
      {isBugReportOpen && (
        <BugReportForm
          user={user?.username || "Unknown User"} // Pass the username
          problem={problem?.title || "Problem Name"}
          onClose={() => setIsBugReportOpen(false)}
        />
      )}

      <div className="workspace-container">
        {loading ? (
          <p>Loading...</p>
        ) : (
          problem && (
            <DragAndDropWorkspace
              problem={problem}
              solutions={solutions}
              level={capitalizedLevel}
              setSelectedLevel={setSelectedLevel}
              activeSolutionIndex={activeSolutionIndex}
              setActiveSolutionIndex={setActiveSolutionIndex}
              selectedLanguage={selectedLanguage}
              isCompletionStatus={isCompletionStatus}
              loading={loading}
              user={user} // Pass user as a prop if needed
              mode={mode}
              setMode={setMode}
              codeSnippet={codeSnippet}
              blocks={blocks}
              setBlocks={setBlocks}
              solutionBlocks={solutionBlocks}
              setSolutionBlocks={setSolutionBlocks}
              setCodeSnippet={setCodeSnippet}
              updateProgress={updateProgress}
              solutionCompleted={solutionCompleted}
              activeSolutionName={activeSolutionName}
              handleLevelChange={handleLevelChange}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ProblemPage;
