import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LearningPaths.css";
import LearningPathPage from "./LearningPathPage";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { ClipLoader } from "react-spinners"; // <- Add this

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const learningPaths = [
  "WARM_UP",
  "TWO_POINTERS",
  "SLIDING_WINDOW",
  "RECURSIVE",
  "STACK",
  "QUEUE",
  "DFS",
  "BFS",
  "BINARY_SEARCH",
  "DYNAMIC_PROGRAMMING",
  "GREEDY",
  "BACKTRACKING",
  "HASH_MAP",
  "MERGE_SORT",
  "SORTING",
];

const formatLabel = (label) =>
  label
    .toLowerCase()
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ");

const LearningPaths = () => {
  const [selectedPath, setSelectedPath] = useState(learningPaths[0]);
  const [progressByPath, setProgressByPath] = useState({});
  const [problemsByPath, setProblemsByPath] = useState({});
  const [isLoading, setIsLoading] = useState(true); // <- New

  useEffect(() => {
    const fetchAllProgress = async () => {
      try {
        const token = localStorage.getItem("token");
        let userId = null;
        let progress = [];

        if (token) {
          const decoded = jwtDecode(token);
          userId = decoded.id;

          const progressRes = await axios.get(`${baseUrl}/progress/${userId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          progress = progressRes.data;
        }

        const pathProgress = {};
        const problemsMap = {};

        await Promise.all(
          learningPaths.map(async (path) => {
            const pathSlug = path.toLowerCase().replace(/_/g, "-");
            const res = await axios.get(
              `${baseUrl}/problems/learning-paths/${pathSlug}`
            );
            const problems = res.data;

            const problemsWithProgress = problems.map((problem) => {
              const beginnerDone = progress.some(
                (p) =>
                  p.problemId === problem.id &&
                  p.level === "BEGINNER" &&
                  p.completed
              );
              const proDone = progress.some(
                (p) =>
                  p.problemId === problem.id && p.level === "PRO" && p.completed
              );

              return {
                ...problem,
                progress: { BEGINNER: beginnerDone, PRO: proDone },
              };
            });

            const totalSolutions = problemsWithProgress.length * 2;
            const completedSolutions = problemsWithProgress.reduce(
              (count, p) =>
                count +
                (p.progress.BEGINNER ? 1 : 0) +
                (p.progress.PRO ? 1 : 0),
              0
            );

            pathProgress[path] = totalSolutions
              ? Math.round((completedSolutions / totalSolutions) * 100)
              : 0;

            problemsMap[path] = problemsWithProgress;
          })
        );

        setProgressByPath(pathProgress);
        setProblemsByPath(problemsMap);
      } catch (err) {
        console.error("Error fetching progress:", err);
      } finally {
        setIsLoading(false); // <- Done loading
      }
    };

    fetchAllProgress();
  }, []);

  return (
    <div className="learning-paths-wrapper">
      <div className="sidebar">
        <h2 className="sidebar-title">Learning Paths</h2>
        {learningPaths.map((path) => (
          <div key={path} className="sidebar-item">
            <button
              className={`sidebar-button ${selectedPath === path ? "active" : ""}`}
              onClick={() => setSelectedPath(path)}
            >
              {formatLabel(path)}
            </button>
            <div className="progress-bar-container">
              <div
                className="progress-bar-fill"
                style={{ width: `${progressByPath[path] || 0}%` }}
              >
                <span className="progress-label-inside">
                  {progressByPath[path] || 0}%
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="content-box">
        {isLoading ? (
          <div className="spinner-wrapper">
            <ClipLoader color="#1abc9c" size={60} />
          </div>
        ) : (
          <LearningPathPage
            pathId={selectedPath.toLowerCase().replace(/_/g, "-")}
            problems={problemsByPath[selectedPath] || []}
          />
        )}
      </div>
    </div>
  );
};

export default LearningPaths;
