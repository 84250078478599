import React, { useState, useEffect } from "react";
import "./BlocksSection.css";
import LevelSwitcher from "./LevelSwitcher";
import BigOInfo from "./BigOInfo";

const BlocksSection = ({
  areBlocksVisible,
  blocks,
  blockHighlightClass,
  highlightedBlock,
  addToSolution,
  showHintButton,
  hintUsed,
  fetchHint,
  onHintClick,
  setHintUsed,
  level,
  handleLevelChange,
  renderSolutionTabs,
  solutionCompleted,
  solutions,
  activeSolutionIndex,
  problem,
  codeSnippet,
}) => {
  const [showBigOView, setShowBigOView] = useState(false);

  const currentSolution = solutions?.[activeSolutionIndex];
  const isOptimal = currentSolution?.isOptimal;
  const timeComplexity = currentSolution?.timeComplexity || "Unknown";
  const spaceComplexity = currentSolution?.spaceComplexity || "Unknown";

  useEffect(() => {
    if (solutionCompleted) {
      setShowBigOView(true);
    }
  }, [solutionCompleted]);

  return (
    <>
      <div className="blocks-sticky-header">
        <div className="left-block">
          <h2 className="block-library-header">Blocks</h2>
        </div>

        <div className="center-block">
          {solutionCompleted && (
            <button
              className="big-o-button"
              onClick={() => setShowBigOView((prev) => !prev)}
            >
              {showBigOView ? "Hide Big O" : "🧠 Big O"}
            </button>
          )}
        </div>

        <div className="right-block">
          {showHintButton && (
            <button
              onClick={onHintClick}
              disabled={hintUsed}
              className={`hint-button ${hintUsed ? "disabled" : ""}`}
            >
              {hintUsed ? "Hint Used" : "Show Hint"}
            </button>
          )}
        </div>
      </div>

      {showBigOView && solutionCompleted ? (
        <BigOInfo
          isOptimal={isOptimal}
          timeComplexity={timeComplexity}
          spaceComplexity={spaceComplexity}
          handleLevelChange={handleLevelChange}
          problemName={problem?.title}
          codeSnippet={codeSnippet}
        />
      ) : (
        <div className={`block-library ${blockHighlightClass}`}>
          {blocks?.map((block) => (
            <button
              key={block.id}
              className={`block-button ${block.type} ${
                highlightedBlock === block.content ? "highlight" : ""
              }`}
              onClick={() => addToSolution(block.content)}
            >
              {block.content}
            </button>
          ))}
        </div>
      )}
    </>
  );
};

export default BlocksSection;
