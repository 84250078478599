import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import "./BigOInfo.css";

const BigOInfo = ({
  isOptimal,
  timeComplexity,
  spaceComplexity,
  handleLevelChange,
  problemName,
  codeSnippet,
}) => {
  const [loadingExplanation, setLoadingExplanation] = useState(false);

  const handleExplainClick = async () => {
    setLoadingExplanation(true);
    try {
      console.log("📦 Sending to /explainBigO:", {
        problemName,
        codeSnippet,
        isOptimal,
        timeComplexity,
        spaceComplexity,
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/problems/explainBigO`,
        {
          problemName,
          codeSnippet,
          isOptimal,
          timeComplexity,
          spaceComplexity,
        }
      );

      Swal.fire({
        title: "📘 Big O Explanation",
        html: `
        <div style="text-align: left; font-size: 15px; line-height: 1.6; padding: 10px;">
          ${formatExplanation(response.data.explanation)}
        </div>
      `,
        icon: "info",
        confirmButtonText: "Got it!",
        width: 650,
        background: "#f9fafb",
        color: "#111827",
        customClass: {
          popup: "big-o-popup",
        },
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Something went wrong while fetching the explanation.",
      });
    } finally {
      setLoadingExplanation(false);
    }
  };

  const formatExplanation = (text) => {
    // Preserve paragraphs
    const paragraphs = text.split(/\n{2,}/).map((para) => {
      // Convert lines with - or * into bullets
      if (/^[-*]/.test(para.trim())) {
        const bullets = para
          .split("\n")
          .map((line) => `<li>${line.replace(/^[-*]\s*/, "").trim()}</li>`)
          .join("");
        return `<ul style="padding-left: 20px; margin: 10px 0;">${bullets}</ul>`;
      }

      // Convert common titles like "Time Complexity - O(n)"
      if (
        /^(\d\.\s*)?(Time|Space|Summary|Two|This|In summary|Overall|Explanation)/i.test(
          para
        )
      ) {
        return `<p style="margin: 10px 0;"><strong>${para.trim()}</strong></p>`;
      }

      // Inline code highlighting for variable names like `a`, `s`, etc.
      const withCode = para.replace(/`([^`]+)`/g, "<code>$1</code>");

      return `<p style="margin: 10px 0;">${withCode}</p>`;
    });

    return paragraphs.join("\n");
  };

  return (
    <div className="big-o-notation-view">
      <div className="optimal-info">
        {isOptimal ? (
          <div className="optimal-text">✅ This is the optimal solution.</div>
        ) : (
          <div className="non-optimal-text">
            ⚠️ This is a non-optimal solution.
          </div>
        )}
      </div>

      <div className="complexity-info">
        <div className="complexity-card">
          <h4>Time Complexity</h4>
          <p className="complexity-value">{timeComplexity}</p>
        </div>
        <div className="complexity-card">
          <h4>Space Complexity</h4>
          <p className="complexity-value">{spaceComplexity}</p>
        </div>
      </div>

      <div className="big-o-buttons">
        <button
          className={`big-o-action-button ${loadingExplanation ? "loading" : ""}`}
          onClick={handleExplainClick}
          disabled={loadingExplanation}
        >
          {loadingExplanation ? "Loading..." : "🤔 Explain this to me"}
        </button>
        <button
          className="big-o-action-button pulse"
          onClick={() => handleLevelChange("Pro")}
        >
          💻 Code it yourself
        </button>
      </div>
    </div>
  );
};

export default BigOInfo;
