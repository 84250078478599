import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useNavigate, Navigate } from "react-router-dom";
import "./Entry.css";

const Entry = () => {
  const [showText, setShowText] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const arrowTimeoutRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // ✅ Check login status from localStorage
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token); // Set true/false
  }, []);

  useEffect(() => {
    const textTimer = setTimeout(() => {
      // setShowText(false);
      setShowButtons(true);
    }, 3500);
    return () => clearTimeout(textTimer);
  }, []);

  const handleShowLoginTooltip = () => {
    setShowArrow(true);

    if (window.loginButtonRef?.current) {
      const loginBtn = window.loginButtonRef.current;
      loginBtn.classList.add("pulse-animation");

      arrowTimeoutRef.current = setTimeout(() => {
        loginBtn.classList.remove("pulse-animation");
        setShowArrow(false);
      }, 4000);
    }
  };

  const token = localStorage.getItem("token");
  if (token) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="entry-container">
      <motion.div
        className="entry-content"
        animate={{
          y: showButtons ? -10 : 0, // Slide up slightly
          marginBottom: showButtons ? 40 : 0, // Makes space for buttons
          opacity: 1,
        }}
        transition={{ type: "spring", stiffness: 60, damping: 12 }}
      >
        <motion.div
          className="logo-wrapper"
          initial={{ x: "100vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 80, damping: 15 }}
        >
          <div className="logo-squares">
            <div className="square square1"></div>
            <div className="square square2"></div>
            <div className="square square3"></div>
            <div className="square square4"></div>
          </div>
          <div className="blockcode-logo">
            <span className="logo-block">Block</span>
            <span className="logo-divider">|</span>
            <span className="logo-code">Code</span>
          </div>
        </motion.div>

        <motion.p
          className="entry-tagline"
          initial={{ x: "-100vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 80,
            duration: 1.5,
            damping: 15,
            delay: 1.6,
          }}
        >
          Cracking <span className="code-style">code</span>, One{" "}
          <span className="block-style">BLOCK</span> at a Time.
        </motion.p>
      </motion.div>

      <motion.div
        className="button-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: showButtons ? 1 : 0 }}
        transition={{ duration: 1.5 }}
      >
        <motion.button
          className="entry-button"
          whileHover={{ scale: 1.1 }}
          onClick={() =>
            navigate("/problems/476", { state: { demoMode: true } })
          }
        >
          Try the Blockcode Demo Here!
        </motion.button>

        {isLoggedIn ? (
          <motion.button
            className="entry-button"
            whileHover={{ scale: 1.1 }}
            onClick={() => navigate("/")}
          >
            Go to Homepage
          </motion.button>
        ) : (
          <motion.button
            className="entry-button"
            whileHover={{ scale: 1.1 }}
            onClick={handleShowLoginTooltip}
          >
            Already a member? Log in here!
          </motion.button>
        )}

        {showArrow && window.loginButtonRef?.current && (
          <div
            className="tooltip-arrow"
            style={{
              position: "fixed",
              top:
                window.loginButtonRef.current.getBoundingClientRect().bottom +
                10 +
                "px",
              left:
                window.loginButtonRef.current.getBoundingClientRect().left +
                "px",
              zIndex: 1000,
              color: "#e74c3c",
              fontWeight: "bold",
              fontSize: "1rem",
            }}
          >
            ⬆ Click Login!
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default Entry;
