import React, { useState } from "react";
import axios from "axios";
import "./BugReportForm.css";

const BugReportForm = ({ user, problem, onClose }) => {
  const [description, setDescription] = useState("");
  const [level, setLevel] = useState(""); // Added state for level
  const [image, setImage] = useState(null); // State for the image upload
  const [message, setMessage] = useState(""); // State for success/error messages

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("user", user);
    formData.append("problem", problem);
    formData.append("level", level);
    formData.append("description", description);
    if (image) {
      formData.append("image", image);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/bug-report`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setMessage("Bug report submitted successfully!");
      // Close the form after 2 seconds
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Error submitting bug report:", error);
      setMessage("Failed to submit bug report. Please try again.");
    }
  };

  return (
    <div className="bug-report-overlay">
      <div className="bug-report-form">
        <h2>Submit a Bug Report</h2>
        {message && (
          <p
            className={`bug-report-message ${
              message.includes("successfully") ? "success" : "error"
            }`}
          >
            {message}
          </p>
        )}{" "}
        {/* Message Display */}
        <form onSubmit={handleSubmit}>
          {/* User Field (Read-Only) */}
          <label htmlFor="user">User:</label>
          <input
            type="text"
            id="user"
            value={user}
            readOnly
            className="readonly-input"
          />

          {/* Problem Field (Read-Only) */}
          <label htmlFor="problem">Problem:</label>
          <input
            type="text"
            id="problem"
            value={problem}
            readOnly
            className="readonly-input"
          />

          {/* Level Dropdown (Its own row) */}
          <div className="form-row">
            <label htmlFor="level">Level:</label>
            <select
              id="level"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              required
            >
              <option value="">Select Level</option>
              <option value="BEGINNER">Beginner</option>
              <option value="INTERMEDIATE">Intermediate</option>
              <option value="EXPERT">Expert</option>
              <option value="PRO">Pro</option>
            </select>
          </div>

          {/* Description Field */}
          <div className="form-row">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe the issue you're facing..."
              required
            ></textarea>
          </div>

          {/* Image Upload */}
          <div className="form-row">
            <label htmlFor="image">Attach Image:</label>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>

          {/* Submit and Cancel Buttons */}
          <div className="button-container">
            <button type="submit" className="submit-button">
              Submit
            </button>
            <button type="button" className="cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BugReportForm;
