import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./ProblemsList.css";
import NavBarV2 from "../components/NavBarV2";
import { jwtDecode } from "jwt-decode";
import ClipLoader from "react-spinners/ClipLoader";

const ProblemsList = () => {
  const [problems, setProblems] = useState([]);
  const [filteredProblems, setFilteredProblems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [difficultyFilter, setDifficultyFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState("FREE_USER");

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const difficulties = ["EASY", "MEDIUM", "HARD"];
  const categories = [
    "ARRAYS",
    "STRINGS",
    "GRAPHS",
    "DYNAMIC_PROGRAMMING",
    "TREES",
    "SEARCH",
    "LINKED_LISTS",
    "STACKS_QUEUES",
    "SORTING",
    "GREEDY",
    "DICTIONARIES_HASHMAPS",
  ];

  const freeAccessTitles = ["Hello World", "FizzBuzz"];

  useEffect(() => {
    const fetchProblems = async () => {
      try {
        const res = await axios.get(`${baseUrl}/problems`, {
          params: { isAdminAdded: true },
        });
        const problems = res.data;
        const token = localStorage.getItem("token");
        let progressByProblem = {};
        let userRole = "FREE_USER";

        if (token) {
          const decoded = jwtDecode(token);
          userRole = decoded.role || "FREE_USER";
          setRole(userRole);

          try {
            const progressRes = await axios.get(
              `${baseUrl}/progress/${decoded.id}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            const progressData = progressRes.data;

            progressData.forEach(
              ({ problemId, level, completed, solutionId }) => {
                if (!progressByProblem[problemId]) {
                  progressByProblem[problemId] = {
                    BEGINNER: false,
                    INTERMEDIATE: { count: 0, solutionIds: [] },
                    EXPERT: { count: 0, solutionIds: [] },
                    PRO: false,
                  };
                }
                if (level === "BEGINNER" && completed) {
                  progressByProblem[problemId].BEGINNER = true;
                } else if (level === "INTERMEDIATE" && completed) {
                  progressByProblem[problemId].INTERMEDIATE.count++;
                  progressByProblem[problemId].INTERMEDIATE.solutionIds.push(
                    solutionId
                  );
                } else if (level === "EXPERT" && completed) {
                  progressByProblem[problemId].EXPERT.count++;
                  progressByProblem[problemId].EXPERT.solutionIds.push(
                    solutionId
                  );
                } else if (level === "PRO" && completed) {
                  progressByProblem[problemId].PRO = true;
                }
              }
            );
          } catch (err) {
            console.warn("Failed to fetch user progress:", err);
          }
        }

        const enriched = problems.map((problem) => ({
          ...problem,
          progress: progressByProblem[problem.id] || {
            BEGINNER: false,
            INTERMEDIATE: { count: 0, solutionIds: [] },
            EXPERT: { count: 0, solutionIds: [] },
            PRO: false,
          },
        }));

        setProblems(enriched);
        setFilteredProblems(enriched);
      } catch (err) {
        console.error("Error fetching problems:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchProblems();
  }, [baseUrl]);

  const handleLevelClick = (problemId, level, progress) => {
    const newMode = level.toUpperCase() === "PRO" ? "code" : "blocks";
    navigate(`/problems/${problemId}`, {
      state: { level: level.toUpperCase(), progress, mode: newMode },
    });
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    filterProblems(term, difficultyFilter, categoryFilter);
  };

  const handleDifficultyChange = (e) => {
    const value = e.target.value;
    setDifficultyFilter(value);
    filterProblems(searchTerm, value, categoryFilter);
  };

  const handleCategoryChange = (e) => {
    const value = e.target.value;
    setCategoryFilter(value);
    filterProblems(searchTerm, difficultyFilter, value);
  };

  const filterProblems = (term, difficulty, category) => {
    let filtered = [...problems];
    if (term)
      filtered = filtered.filter((p) => p.title.toLowerCase().includes(term));
    if (difficulty)
      filtered = filtered.filter((p) => p.difficulty === difficulty);
    if (category) filtered = filtered.filter((p) => p.category === category);
    setFilteredProblems(filtered);
  };

  const getDifficultyStyle = (difficulty) => {
    switch (difficulty) {
      case "EASY":
        return "difficulty easy";
      case "MEDIUM":
        return "difficulty medium";
      case "HARD":
        return "difficulty hard";
      default:
        return "difficulty";
    }
  };

  return (
    <div className="problems-list-container">
      {loading ? (
        <div className="loading-container">
          <ClipLoader size={50} color={"#3498db"} loading={loading} />
          <p>Loading problems...</p>
        </div>
      ) : (
        <>
          {/* <div className="header-container"> */}
          <div className="header-title-bar">
            <h1 className="header-title">Coding Challenge Library</h1>
            <div
              className="learning-path-cta"
              onClick={() => navigate("/learning-paths")}
            >
              <div className="cta-content">
                <span className="cta-icon">🧭</span>
                <div className="cta-text">
                  <strong>Not sure where to start?</strong>
                  <p>Explore our Learning Paths</p>
                </div>
                <span className="cta-arrow">→</span>
              </div>
            </div>
          </div>

          <div className="header-controls condensed-header-controls">
            <input
              type="text"
              className="search-bar condensed"
              placeholder="🔍 Search problems..."
              value={searchTerm}
              onChange={handleSearch}
            />
            <select
              className="filter-dropdown condensed"
              value={difficultyFilter}
              onChange={handleDifficultyChange}
            >
              <option value="">All Difficulties</option>
              {difficulties.map((d) => (
                <option key={d} value={d}>
                  {d}
                </option>
              ))}
            </select>
            <select
              className="filter-dropdown condensed"
              value={categoryFilter}
              onChange={handleCategoryChange}
            >
              <option value="">All Categories</option>
              {categories.map((c) => (
                <option key={c} value={c}>
                  {c.replace("_", " ")}
                </option>
              ))}
            </select>
          </div>

          <div className="problems-container">
            {filteredProblems.map((problem) => {
              const {
                BEGINNER,
                INTERMEDIATE = { solutionIds: [] },
                PRO,
              } = problem.progress || {};

              const isLocked =
                role === "FREE_USER" &&
                !freeAccessTitles.includes(problem.title);

              const isFullySolved =
                BEGINNER && INTERMEDIATE.solutionIds.length === 4 && PRO;

              return (
                <div className="problem-wrapper" key={problem.id}>
                  <div
                    className={`problem-row ${isLocked ? "locked" : ""} ${isFullySolved ? "problem-solved" : ""}`}
                  >
                    <div>
                      <h3 className="problem-title">{problem.title}</h3>
                      <span className={getDifficultyStyle(problem.difficulty)}>
                        {problem.difficulty}
                      </span>
                      <span className="category-pill">
                        {problem.category.replace("_", " ")}
                      </span>
                    </div>
                    <div className="level-buttons-container">
                      <div className="level-buttons-row">
                        <div className="block-mode-group">
                          <button
                            disabled={isLocked}
                            onClick={() =>
                              handleLevelClick(
                                problem.id,
                                "beginner",
                                problem.progress
                              )
                            }
                            className={`level-button beginner ${BEGINNER ? "level-gold" : ""}`}
                          >
                            Beginner
                          </button>
                          <button
                            disabled={isLocked}
                            onClick={() =>
                              handleLevelClick(
                                problem.id,
                                "intermediate",
                                problem.progress
                              )
                            }
                            className={`level-button intermediate ${INTERMEDIATE.solutionIds.length === 4 ? "level-gold" : ""}`}
                          >
                            Intermediate
                          </button>
                        </div>
                        <div className="mode-divider"></div>
                        <div className="code-mode-group">
                          <button
                            disabled={isLocked}
                            onClick={() =>
                              handleLevelClick(
                                problem.id,
                                "pro",
                                problem.progress
                              )
                            }
                            className={`level-button pro ${PRO ? "level-gold" : ""}`}
                          >
                            Pro
                          </button>
                        </div>
                      </div>
                      <div className="mode-labels">
                        <div className="mode-label block-label">Block Mode</div>
                        <div className="mode-label code-label">Code Mode</div>
                      </div>
                    </div>
                  </div>

                  {isLocked && (
                    <div className="lock-overlay">
                      <div className="lock-icon">🔒</div>
                      <p className="lock-message">
                        <button
                          className="upgrade-inline-button"
                          onClick={() => navigate("/membership")}
                        >
                          Upgrade
                        </button>{" "}
                        to unlock this problem
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {/* </div> */}
        </>
      )}
    </div>
  );
};

export default ProblemsList;
