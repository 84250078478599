import React, { useState } from "react";
import "./SurveyForm.css";
import axios from "axios";

const extractUsername = () => {
  const usernameElement = document.querySelector(".navbar-user");
  if (usernameElement) {
    const text = usernameElement.textContent || usernameElement.innerText;
    return text.replace("Hello, ", "").trim();
  }
  return "Anonymous";
};

const SurveyForm = () => {
  const [formData, setFormData] = useState({
    background: "",
    otherBackground: "",
    browser: "",
    otherBrowser: "",
    usabilityRating: 3,
    navigation: "",
    taskEase: "",
    bugsEncountered: "",
    designFeedback: "",
    difficultyLevels: 3,
    blockFeatureRating: 3,
    likelihoodToUse: 5,
    featuresToAdd: "",
    changesToWebsite: "",
    additionalComments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Extract the username from the navbar DOM
      const username = extractUsername();
  
      // Append the username to the formData
      const surveyData = {
        ...formData,
        name: username || "Anonymous",
      };
  
      // Send the survey data to the server
      const response = await axios.post(
        "http://localhost:5000/survey",
        { responses: surveyData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        alert("Survey submitted successfully!");
        setFormData({
          background: "",
          otherBackground: "",
          browser: "",
          otherBrowser: "",
          usabilityRating: 3,
          navigation: "",
          taskEase: "",
          bugsEncountered: "",
          designFeedback: "",
          difficultyLevels: 3,
          blockFeatureRating: 3,
          likelihoodToUse: 5,
          featuresToAdd: "",
          changesToWebsite: "",
          additionalComments: "",
        }); // Reset form after submission
      }
    } catch (error) {
      console.error("Error submitting survey:", error);
      alert("Failed to submit survey. Please try again later.");
    }
  };
    
  return (
    <div className="survey-container">
      <h1 className="survey-title">Post-Testing Feedback Survey</h1>
      <form onSubmit={handleSubmit} className="survey-form">
        {/* Section 1: General Information */}
        <h2>Section 1: General Information</h2>

        <div className="form-group">
          <label>What is your background in software development?</label>
          <select
            name="background"
            value={formData.background}
            onChange={handleChange}
            required
          >
            <option value="">-- Select --</option>
            <option value="new">New Developer (0-1 year)</option>
            <option value="intermediate">
              Intermediate Developer (1-3 years)
            </option>
            <option value="experienced">
              Experienced Developer (3+ years)
            </option>
            <option value="other">Other</option>
          </select>
          {formData.background === "other" && (
            <input
              type="text"
              name="otherBackground"
              placeholder="Please specify..."
              value={formData.otherBackground}
              onChange={handleChange}
              required
            />
          )}
        </div>

        <div className="form-group">
          <label>What browser did you use for testing?</label>
          <select
            name="browser"
            value={formData.browser}
            onChange={handleChange}
            required
          >
            <option value="">-- Select --</option>
            <option value="chrome">Google Chrome</option>
            <option value="firefox">Firefox</option>
            <option value="safari">Safari</option>
            <option value="edge">Microsoft Edge</option>
            <option value="other">Other</option>
          </select>
          {formData.browser === "other" && (
            <input
              type="text"
              name="otherBrowser"
              placeholder="Please specify..."
              value={formData.otherBrowser}
              onChange={handleChange}
              required
            />
          )}
        </div>

        {/* Section 2: Usability and Experience */}
        <h2>Section 2: Usability and Experience</h2>

        <div className="form-group">
          <label>
            On a scale of 1-5, how would you rate the overall usability of the
            site?
          </label>
          <input
            type="range"
            name="usabilityRating"
            min="1"
            max="5"
            value={formData.usabilityRating}
            onChange={handleChange}
          />
          <p>{formData.usabilityRating} (1 = Very Difficult, 5 = Very Easy)</p>
        </div>

        <div className="form-group">
          <label>Did you find the navigation intuitive and easy to use?</label>
          <select
            name="navigation"
            value={formData.navigation}
            onChange={handleChange}
            required
          >
            <option value="">-- Select --</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          {formData.navigation === "no" && (
            <textarea
              name="navigationExplain"
              value={formData.navigationExplain || ""}
              onChange={handleChange}
              placeholder="Please explain where navigation was confusing..."
              required
            ></textarea>
          )}
        </div>

        <div className="form-group">
          <label>Were you able to solve problems with ease?</label>
          <select
            name="taskEase"
            value={formData.taskEase}
            onChange={handleChange}
            required
          >
            <option value="">-- Select --</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          {formData.taskEase === "no" && (
            <textarea
              name="taskEaseExplain"
              value={formData.taskEaseExplain || ""}
              onChange={handleChange}
              placeholder="Please explain the difficulties faced..."
              required
            ></textarea>
          )}
        </div>

        <div className="form-group">
          <label>
            Did you encounter any bugs or errors during your testing?
          </label>
          <select
            name="bugsEncountered"
            value={formData.bugsEncountered}
            onChange={handleChange}
            required
          >
            <option value="">-- Select --</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          {formData.bugsEncountered === "yes" && (
            <textarea
              name="bugDetails"
              value={formData.bugDetails || ""}
              onChange={handleChange}
              placeholder="Please clarify the bugs encountered..."
              required
            ></textarea>
          )}
        </div>

        <div className="form-group">
          <label>Was the site’s design and layout visually appealing?</label>
          <select
            name="designFeedback"
            value={formData.designFeedback}
            onChange={handleChange}
            required
          >
            <option value="">-- Select --</option>
            <option value="yes">Yes</option>
            <option value="somewhat">Somewhat</option>
            <option value="no">No</option>
          </select>
          {(formData.designFeedback === "somewhat" ||
            formData.designFeedback === "no") && (
            <textarea
              name="designChangeSuggestions"
              value={formData.designChangeSuggestions || ""}
              onChange={handleChange}
              placeholder="What would you add or change to enhance the user experience?"
              required
            ></textarea>
          )}
        </div>

        {/* Section 3: Specific Features */}
        <h2>Section 3: Specific Features</h2>

        <div className="form-group">
          <label>
            Did you like the different difficulty levels? (Scale of 1-5)
          </label>
          <input
            type="range"
            name="difficultyLevels"
            min="1"
            max="5"
            value={formData.difficultyLevels}
            onChange={handleChange}
          />
          <p>{formData.difficultyLevels} (1 = Not At All, 5 = Loved It)</p>
        </div>

        <div className="form-group">
          <label>
            On a scale of 1-5, how did you like the block feature compared to
            other sites (e.g., LeetCode, HackerRank)?
          </label>
          <input
            type="range"
            name="blockFeatureRating"
            min="1"
            max="5"
            value={formData.blockFeatureRating}
            onChange={handleChange}
          />
          <p>{formData.blockFeatureRating} (1 = Not At All, 5 = Loved It)</p>
        </div>

        {/* Section 4: Open Feedback */}
        <h2>Section 4: Open Feedback</h2>

        <div className="form-group">
          <label>What features would you like to add?</label>
          <textarea
            name="featuresToAdd"
            value={formData.featuresToAdd}
            onChange={handleChange}
            placeholder="Your suggestions..."
          ></textarea>
        </div>

        <div className="form-group">
          <label>What would you change on the website?</label>
          <textarea
            name="changesToWebsite"
            value={formData.changesToWebsite}
            onChange={handleChange}
            placeholder="Your suggestions..."
          ></textarea>
        </div>

        <div className="form-group">
          <label>Any additional feedback?</label>
          <textarea
            name="additionalComments"
            value={formData.additionalComments}
            onChange={handleChange}
            placeholder="Your feedback..."
          ></textarea>
        </div>

        <div className="form-group">
          <label>
            On a scale of 1-10, how likely are you to use this site again?
          </label>
          <input
            type="range"
            name="likelihoodToUse"
            min="1"
            max="10"
            value={formData.likelihoodToUse}
            onChange={handleChange}
          />
          <p>
            {formData.likelihoodToUse} (1 = Wouldn't Use, 10 = Would Use All the
            Time)
          </p>
        </div>

        <button type="submit" className="submit-button">
          Submit Feedback
        </button>
      </form>
    </div>
  );
};

export default SurveyForm;
