import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CheckCircle } from "lucide-react";
import "./BlockFlowAnimation.css";

const sections = [
  { id: "left-stack", label: "Problem", gridArea: "left-stack" }, // 🆕
  { id: "bottom-left", label: "Blocks", gridArea: "bottom-left" },
  { id: "top-left", label: "Solution", gridArea: "top-left" },
  { id: "top-right", label: "Code", gridArea: "top-right" },
  { id: "bottom-right", label: "Output", gridArea: "bottom-right" },
];

const BlockFlowAnimation = ({ setHoveredSection, clearHoveredSection }) => {
  const [step, setStep] = useState(0);
  const [reset, setReset] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setStep((prev) => {
        const next = (prev + 1) % 10;
        if (next === 0) {
          setReset(true);
          setTimeout(() => setReset(false), 50);
        }
        return next;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Green block position
  const getGreenPos = () => {
    if (step === 0) return { top: 311, left: 200 }; // Start in Blocks
    if (step >= 1 && step <= 9) return { top: 40, left: 200 }; // Stay in Solution
    return null;
  };

  // Blue block position
  const getBluePos = () => {
    if (step === 0 || step === 1 || step === 2 || step === 3)
      return { top: 311, left: 250 }; // Stay in Blocks
    if (step >= 4 && step <= 9) return { top: 80, left: 200 }; // Move to Solution below green
    return null;
  };

  const getYellowPos = () => {
    if (
      step === 0 ||
      step === 1 ||
      step === 2 ||
      step === 3 ||
      step === 4 ||
      step === 5 ||
      step === 6
    )
      return { top: 370, left: 200 }; // Start and stay in Blocks
    if (step >= 7) return { top: 120, left: 200 }; // Move to Solution after blue
    return null;
  };

  const yellowPos = getYellowPos();
  const greenPos = getGreenPos();
  const bluePos = getBluePos();

  return (
    <div className="animation-wrapper">
      <div className="grid-container">
        {sections.map((section) => (
          <div
            key={section.id}
            className={`grid-box ${section.gridArea}`}
            onMouseEnter={() => setHoveredSection(section.label)}
            onMouseLeave={clearHoveredSection}
          >
            <span className="section-label">{section.label}</span>
            {section.gridArea === "top-right" && (
              <div className="code-editor-shell">
                {/* <div className="code-editor-header">
                  <span className="editor-title">Code</span>
                </div> */}
                <div className="code-editor-body" />
              </div>
            )}

            {section.gridArea === "left-stack" && (
              <div className="problem-placeholder">
                <div className="gray-box title-box shade-1" />
                <div className="gray-box line-box shade-2" />
                <div className="gray-box line-box shade-3" />
                <div className="gray-box line-box shade-1" />
                <div className="gray-box line-box short shade-2" />
                <div className="gray-box line-box shade-3" />
                <div className="gray-box line-box shade-4" />
                <div className="gray-box example-header shade-1" />
                <div className="gray-box input-box shade-2" />
                <div className="gray-box output-box shade-3" />
                <div className="gray-box line-box shade-4" />
                <div className="gray-box line-box short shade-1" />
                <div className="gray-box line-box shade-2" />
                <div className="gray-box line-box short shade-3" />
                <div className="gray-box line-box shade-4" />
                <div className="gray-box line-box shade-1" />
                <div className="gray-box line-box short shade-2" />
                <div className="gray-box line-box shade-3" />
              </div>
            )}
          </div>
        ))}

        {/* Green block stays in solution after step 1 */}
        {!reset && greenPos && (
          <motion.div
            className="floating-block"
            animate={greenPos}
            transition={{ duration: step === 1 ? 0.6 : 0 }}
          />
        )}

        {/* Blue block animates separately starting at step 4 */}
        {!reset && bluePos && (
          <motion.div
            className="floating-block blue"
            animate={bluePos}
            transition={{ duration: step === 4 ? 0.6 : 0 }}
          />
        )}

        {!reset && yellowPos && (
          <motion.div
            className="floating-block yellow"
            animate={yellowPos}
            transition={{ duration: step === 7 ? 0.6 : 0 }}
          />
        )}

        {/* Green code block appears at step 2 */}
        {/* Green long block with "Hello" */}
        {step >= 2 && (
          <motion.div
            className="long-block top-right"
            style={{ top: 30 }}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className="code-line">
              <span className="line-number">1</span>{" "}
              <span className="code-keyword">const</span>{" "}
              <span className="code-variable">message</span> ={" "}
              <span className="code-string">'Hello World'</span>;
            </span>
          </motion.div>
        )}

        {step >= 5 && (
          <motion.div
            className="long-block top-right"
            style={{ top: 45 }}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className="code-line">
              <span className="line-number">2</span>{" "}
              <span className="code-method">console</span>.
              <span className="code-function">log</span>(
              <span className="code-variable">message</span>);
            </span>
          </motion.div>
        )}

        {step >= 8 && (
          <motion.div
            className="long-block top-right"
            style={{ top: 60 }}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className="code-line">
              <span className="line-number">2</span>{" "}
              <span className="code-keyword">for</span> (
              <span className="code-keyword">let</span>{" "}
              <span className="code-variable">i</span> ={" "}
              <span className="code-number">0</span>;{" "}
              <span className="code-variable">i</span> &lt;{" "}
              <span className="code-number">5</span>;{" "}
              <span className="code-variable">i</span>++ ) {"{"}
            </span>
          </motion.div>
        )}

        {step >= 8 && (
          <motion.div
            className="long-block top-right"
            style={{ top: 75 }}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <span className="code-line" style={{ paddingLeft: 20 }}>
              <span className="code-method">console</span>.
              <span className="code-function">log</span>(
              <span className="code-variable">i</span>);
            </span>
          </motion.div>
        )}

        {step >= 8 && (
          <motion.div
            className="long-block top-right"
            style={{ top: 90 }}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <span className="code-line">{"}"}</span>
          </motion.div>
        )}

        {/* ✅ Final success after both blocks complete */}
        <AnimatePresence>
          {step === 9 && (
            <motion.div
              key="success"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="success-icon bottom-right"
            >
              <CheckCircle size={68} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default BlockFlowAnimation;
