import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ForgotPasswordModal from "./ForgotPasswordModal";
import "./LoginModal.css";


const LoginModal = ({ onLogin, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();



  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/users/login`,
        { email, password },
        { withCredentials: true }
      );
  
      onLogin({
        token: response.data.accessToken,
        userId: response.data.userId,
      });
  
      // ✅ Do not navigate here. Let Entry.jsx handle it.
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  
  
  

  const handleOutsideClick = (e) => {
    // Close modal if user clicks outside the modal content
    if (e.target.className === 'login-modal-wrapper') {
        onClose();
    }
  };

  const handleRegisterClick = () => {
    navigate("/register");
    onClose();
  }

  return (
    <div className="login-modal-wrapper" onClick={handleOutsideClick}>
        <div className="login-modal">
          <button className="login-modal-close" onClick={onClose}>
            x
          </button>
          <form onSubmit={handleLogin}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit" className="login-submit-button">
              Login
            </button>
          </form>
          <p className="or-text">OR</p>
          <a href={`${baseUrl}/users/auth/google`} className="google-login">
            Login with Google
          </a>
          <Link to="/register" className="register-button" onClick={handleRegisterClick}>Create Account</Link>
          <button className="forgot-password-button" onClick={() => setShowForgotPasswordModal(true)}>
          Forgot Password?
        </button>
        {showForgotPasswordModal && <ForgotPasswordModal onClose={() => setShowForgotPasswordModal(false)} />}
        </div>
    </div>
  );
};

export default LoginModal;
