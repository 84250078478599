import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  FaPuzzlePiece,
  FaUserGraduate,
  FaCode,
  FaStar,
  FaMedal,
  FaTrophy,
} from "react-icons/fa";
import NavBarV2 from "../components/NavBarV2";
import "./HomePage.css";
import BlockAnimation from "../components/BlockAnimation";
import Membership from "./Membership";
import ProblemFlowAnimation from "../components/ProblemFlowAnimation";
import BlockFlowAnimation from "../components/BlockFlowAnimation";

const hoverDescriptions = {
  Problem: (
    <>
      {/* <h3 className="bubble-title">The Challenge</h3> */}
      <p>
        This is where the coding prompt lives — including inputs, outputs, and
        example cases.
      </p>
    </>
  ),
  Blocks: (
    <>
      {/* <h3 className="bubble-title">Visual Blocks</h3> */}
      <p>
        "Pseudocode" blocks that represent real code — no syntax, just
        structure.
      </p>
    </>
  ),
  Solution: (
    <>
      {/* <h3 className="bubble-title">Solution Area</h3> */}
      <p>
        Arrange your blocks here to solve the problem visually. This is your
        logic canvas.
      </p>
    </>
  ),
  Code: (
    <>
      {/* <h3 className="bubble-title">Generated Code</h3> */}
      <p>
        See the JavaScript code that your block solution produces in real time.
      </p>
    </>
  ),
  Output: (
    <>
      {/* <h3 className="bubble-title">Output Panel</h3> */}
      <p>
        Run your code and check the result against sample test cases instantly.
      </p>
    </>
  ),
};

const HomePage = () => {
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [viewMode, setViewMode] = useState("block");
  const [hoveredSection, setHoveredSection] = useState(null);
  const hoverTimeoutRef = useRef(null);

  const handleVideoOpen = () => {
    setIsVideoVisible(true);
  };

  const handleVideoClose = () => {
    setIsVideoVisible(false);
  };

  return (
    <div className="homepage-container">
      {/* Logo in top left */}
      <div className="top-logo-bar">
        <div className="logo-wrapper">
          <div className="logo-squares">
            <div className="square square1"></div>
            <div className="square square2"></div>
            <div className="square square3"></div>
            <div className="square square4"></div>
          </div>
          <div className="blockcode-logo">
            <span className="logo-block">Block</span>
            <span className="logo-divider">|</span>
            <span className="logo-code">Code</span>
          </div>
        </div>
      </div>

      <div className="animation-buttons-container">
        {/* Centered animation */}

        {/* Headline and buttons below animation */}
        <div className="centered-headline-section">
          <h1>
            <div>
              Build <span className="highlight-home">Algorithms,</span>
            </div>
            <div>
              Master <span className="highlight-home">Coding</span>
            </div>
          </h1>
          <p>
            A smarter, interactive way to learn data structures and algorithms.
          </p>

          <div className="hero-buttons-vertical">
            <Link to="/problems" className="cta-button primary">
              Browse Problems
            </Link>
            <Link to="/learning-paths" className="cta-button secondary">
              Learning Paths
            </Link>
            <Link to="/create-problem" className="cta-button tertiary">
              Import Your Own Problem
            </Link>
          </div>
        </div>
      </div>

      <div
        className="scroll-down-arrow"
        onClick={() => {
          document
            .querySelector(".animation-holder-row")
            .scrollIntoView({ behavior: "smooth" });
        }}
      >
        <span className="arrow">Learn More</span>
        <span className="arrow">&#x25BC;</span>
        <span className="arrow">&#x25BC;</span>
      </div>

      <div className="animation-holder-row">
        <div className="animation-left">
          <BlockFlowAnimation
            setHoveredSection={(section) => {
              if (hoverTimeoutRef.current) {
                clearTimeout(hoverTimeoutRef.current);
              }
              setHoveredSection(section);
            }}
            clearHoveredSection={() => {
              hoverTimeoutRef.current = setTimeout(() => {
                setHoveredSection(null);
              }, 100); // ← feel free to tweak (80–150ms is a good sweet spot)
            }}
          />
        </div>
        <div className="animation-right">
          {/* <div className="animation-info-container">
            {hoveredSection ? (
              hoverDescriptions[hoveredSection]
            ) : (
              <div className="bubble-default">
                Hover over a section to learn more about it!
              </div>
            )}
          </div> */}
          <div className="animation-info-container-multi">
            {Object.keys(hoverDescriptions).map((key) => (
              <div
                key={key}
                className={`info-section ${hoveredSection === key ? "expanded" : ""}`}
                onMouseEnter={() => {
                  if (hoverTimeoutRef.current)
                    clearTimeout(hoverTimeoutRef.current);
                  setHoveredSection(key);
                }}
                onMouseLeave={() => {
                  hoverTimeoutRef.current = setTimeout(() => {
                    setHoveredSection(null);
                  }, 100);
                }}
              >
                <div className="info-title">{key}</div>
                {hoveredSection === key && (
                  <div className="info-description">
                    {hoverDescriptions[key]}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Video Pop-up */}
      {/* {isVideoVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="modal-close-button" onClick={handleVideoClose}>
              ×
            </button>
            <video controls width="600" autoPlay loop>
              <source src="/problemExample.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )} */}

      {/* Why BlockCode Section */}
      {/* <div className="why-section">
        <h2>What Makes BlockCode Different?</h2>
        <div className="why-grid">
          <div className="why-item">
            <div className="icon-circle">
              <FaPuzzlePiece size={28} />
            </div>
            <h3>Interactive Learning</h3>
            <p>
              Construct your code one block at a time—no syntax, just logic.
            </p>
          </div>

          <div className="why-item">
            <div className="icon-circle">
              <FaUserGraduate size={28} />
            </div>
            <h3>Guided Learning Paths</h3>
            <p>
              Follow curated learning paths designed to help you master specific
              algorithms, data structures, or prep for coding interviews.
            </p>
          </div>

          <div className="why-item">
            <div className="icon-circle">
              <FaCode size={28} />
            </div>
            <h3>Turn Any Problem Into Blocks</h3>
            <p>
              Stuck on a coding problem? Import it from LeetCode, HackerRank, or
              anywhere else, and let the blocks guide your way.
            </p>
          </div>

          <div className="why-item">
            <div className="icon-circle">
              <FaStar size={28} />
            </div>
            <h3>Multiple Solution Paths</h3>
            <p>
              Learn different ways to solve problems and understand why one
              solution may be more optimal than another.
            </p>
          </div>

          <div className="why-item">
            <div className="icon-circle">
              <FaMedal size={28} />
            </div>
            <h3>Code Mode for Pros</h3>
            <p>
              Switch to a built-in code editor and solve problems like you would
              in a real interview.
            </p>
          </div>

          <div className="why-item">
            <div className="icon-circle">
              <FaCode size={28} />
            </div>
            <h3>Big O Learning Tools</h3>
            <p>
              Reinforce your understanding of time and space complexity with
              interactive prompts and simple explanations.
            </p>
          </div>

          <div className="why-item center-last">
            <div className="icon-circle">
              <FaTrophy size={28} />
            </div>
            <h3>XP & Achievements</h3>
            <p>
              Stay motivated by earning XP for every problem you solve. Unlock
              achievements, level up, and celebrate your coding wins!
            </p>
          </div>
        </div>
      </div> */}

      {/* Call to Action */}
      <div className="cta-section">
        <h2>Ready to Build with Blocks?</h2>
        <p className="cta-subtext">
          Learn algorithms, master interviews, and actually enjoy the process.
          BlockCode makes coding visual and fun.
        </p>
        <div className="cta-buttons">
          <Link to="/problems" className="cta-button">
            Browse Problems
          </Link>
          <Link to="/learning-paths" className="cta-button secondary">
            Start Learning Path
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
