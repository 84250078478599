import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import "./BlockAnimation.css";

const BlockAnimation = () => {
  const [dragging, setDragging] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Track screen width to adjust animation
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
    
    handleResize(); // Call on component mount
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Spring animation to move the block
  const blockSpring = useSpring({
    transform: dragging
      ? isSmallScreen
        ? "translate3d(0px, 150px, 0)" // Move vertically on small screens
        : "translate3d(300px, 0px, 0)" // Move horizontally on larger screens
      : "translate3d(0px, 0px, 0)", // Default starting position
    config: { tension: 170, friction: 26 },
  });

  // Handle click to simulate drag
  const handleDrag = () => {
    setDragging(!dragging);
  };

  return (
    <div className="animation-container">
      <div className="blocks-solution">
        <div className="block-section">
          <h3>Blocks</h3>
          <animated.div
            className="block"
            style={blockSpring}
            onClick={handleDrag}
          >
            If Statement
          </animated.div>
        </div>
        <div className="block-section">
          <h3>Solution</h3>
        </div>
      </div>

      <div className="solution-section">
        <h3>Code</h3>
        <div className="drop-zone">
          {dragging && (
            <div className="dropped-block">
              {"if (x < arr.length) return x"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlockAnimation;
