import React, { useState } from "react";
import axios from "axios";
import "./ForgotPasswordModal.css";

const ForgotPasswordModal = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/users/forgot-password`,
        { email },
        {
          withCredentials: true,
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      console.error("Error during password reset:", error);
      setMessage("Failed to reset password. Please try again.");
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target.className === 'forgot-password-modal-wrapper') {
      onClose();
    }
  };

  return (
    <div className="forgot-password-modal-wrapper" onClick={handleOutsideClick}>
      <div className="forgot-password-modal">
        <button className="forgot-password-modal-close" onClick={onClose}>
          x
        </button>
        <form onSubmit={handleForgotPassword}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="forgot-password-submit-button">
            Reset Password
          </button>
        </form>
        {message && <p className="forgot-password-message">{message}</p>}
      </div>
    </div>
  );
};

export default ForgotPasswordModal;