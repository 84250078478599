import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoadingScreen.css";
import { toast } from "react-toastify"; // Import toast for notifications
import "react-toastify/dist/ReactToastify.css";

const LoadingScreen = () => {
  const navigate = useNavigate();
  const messages = [
    "Analyzing problem details",
    "Generating solution blocks",
    "Taking a quick coffee break",
    "Validating code structure",
    "Optimizing performance",
    "Are we there yettttt",
  ];

  const [currentMessage, setCurrentMessage] = useState(messages[0]);
  const [dots, setDots] = useState("");

  useEffect(() => {
    // Cycle through messages every 5 seconds
    const messageInterval = setInterval(() => {
      setCurrentMessage((prevMessage) => {
        const currentIndex = messages.indexOf(prevMessage);
        const nextIndex = (currentIndex + 1) % messages.length;
        return messages[nextIndex];
      });
    }, 5000);

    // Animate dots "..."
    const dotsInterval = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? prev + "." : ""));
    }, 500);

    return () => {
      clearInterval(messageInterval);
      clearInterval(dotsInterval);
    };
  }, []);

  // Navigate to the /problems page
  const handleNavigateToProblems = () => {
    navigate("/problems");
  };

  return (
    <div className="loading-page">
      <div className="loading-screen">
        {/* <div className="loading-animation">
          <div className="loading-terminal">
            <p className="loading-message">
              {currentMessage}
              <span className="dots">{dots}</span>
            </p>
          </div>
        </div> */}
        <div className="browse-message">
        <p>
          Creating your problem may take a minute or two. Want to browse our
          other problems while you wait?
        </p>
        <button
          className="browse-problems-button"
          onClick={handleNavigateToProblems}
        >
          Browse Problems
        </button>
      </div>
      </div>

    </div>
  );
};

export default LoadingScreen;
